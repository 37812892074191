import { ProductOrigin } from '../@types/Company';

export function ProductOriginLabel({ origin }: { origin: ProductOrigin }) {
	return (
		<span
			style={{
				color:
					origin === 'corpway' ? 'var(--primary-blue)' : 'var(--primary-red)',
			}}
		>
			{origin === 'corpway' ? 'Bounty Control' : 'Benefícios Flexíveis'}
		</span>
	);
}
