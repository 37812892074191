import { Company } from '../../@types/Company';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface CompaniesResponse {
	totalCompanies: number;
	companies: Company[];
}

export async function getCompaniesWithBenefits(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<CompaniesResponse>(
		`/api/v1/cs/companies/with-benefits/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCompanies(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<CompaniesResponse>(`/api/v1/cs/companies/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function getCompaniesSelect() {
	const { data } = await api.get(`/api/v1/cs/companies/select`);
	return data;
}

export async function getCompanyFundsIn(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null,
	company_id: string
) {
	const { data } = await api.get(
		`/api/v1/cs/companies/${company_id}/funds_in/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCompanyFundsInReport(
	filterParams: FilterParams[],
	sortColumn: SortColumn | null,
	company_id: string
) {
	const { data } = await api.get(
		`/api/v1/cs/companies/${company_id}/funds_in/report/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

interface GetCompanyBalanceReturn {
	corpway_balance: number;
	multiflex_balance: number;
	total_corpway_cards_balance: number;
}
export async function getCompanyBalance(companyId?: string) {
	const { data } = await api.get<GetCompanyBalanceReturn>(
		`/api/v1/cs/companies/${companyId}/balances`
	);

	return data;
}
