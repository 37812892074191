// expected format: yyyy-mm
export function getLastDayOfMonth(monthDateString: string) {
	// Parse the year and month from the input string
	const [year, month] = monthDateString.split('-').map(Number);

	// Create a Date object for the last day of the month
	const lastDayOfMonth = new Date(year, month, 0);

	// Extract the day part from the Date objects
	const lastDay = lastDayOfMonth.getDate();

	return lastDay;
}

export function getFirstDayOfMonth(monthDateString: string) {
	return '01';
}

export function getMinMonthValue() {
	const minDate = new Date();
	minDate.setMonth(minDate.getMonth() - 17);

	const formattedMinMonth = `${minDate.getFullYear()}-${String(
		minDate.getMonth() + 1
	).padStart(2, '0')}`;

	return formattedMinMonth;
}

export function getMaxMonthValue() {
	const maxDate = new Date();

	const formattedMaxMonth = `${maxDate.getFullYear()}-${String(
		maxDate.getMonth() + 1
	).padStart(2, '0')}`;

	return formattedMaxMonth;
}
