import { StyleSheet } from '@react-pdf/renderer';
import styled from 'styled-components';
import { OptionsButton } from '../Button';

export const PDFstyles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		columnGap: 10,
		marginBottom: 15,
		paddingHorizontal: 25,
		paddingTop: 25,
	},
	logo: {
		width: 100,
		height: 'auto',
		objectFit: 'contain',
		marginRight: 40,
	},
	headerInfo: {
		display: 'flex',
		flex: 1,
		width: '100%',
		flexDirection: 'column',
	},
	headerInfoLabel: {
		fontFamily: 'Poppins',
		fontSize: 9,
		fontWeight: 600,
	},
	headerInfoData: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		fontFamily: 'Poppins',
		fontSize: 9,
		fontWeight: 600,
	},
	subHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingHorizontal: 25,
		paddingVertical: 20,
		backgroundColor: '#F0F3F8',
	},
	titleContainer: {
		width: '40%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		columnGap: 15,
	},
	reportLogo: {
		width: 35,
		objectFit: 'contain',
	},
	reportTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	reportTitle: {
		fontFamily: 'Poppins',
		fontSize: 16,
		fontWeight: 600,
	},
	subHeaderDataContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		columnGap: 15,
		rowGap: 12,
		marginLeft: '50px',
	},
	subHeaderInfo: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: 3,
	},
	subHeaderLabel: {
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 10,
		fontWeight: 600,
	},
	subHeaderData: {
		fontFamily: 'Poppins',
		fontSize: 10,
		maxWidth: '90%',
	},
	tableContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		paddingHorizontal: 25,
	},
	columnTitleContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		columnGap: 5,
		fontWeight: 600,
	},
	tableHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 10,
	},
	columnTitle: {
		fontFamily: 'Poppins',
		fontSize: 11,
		fontWeight: 600,
	},
	tableRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		columnGap: 10,
		marginTop: 8,
	},
	columnData: {
		width: '100%',
		maxHeight: '60px',
		color: '#575757',
		fontFamily: 'Poppins',
		fontSize: 11,
		overflow: 'hidden',
	},
});

export function getColumnTitleStyle(width: number | string) {
	const column = StyleSheet.create({
		style: {
			paddingBottom: 5,
			borderBottomColor: '#C6C6C6',
			borderBottomStyle: 'solid',
			borderBottomWidth: 2,
			minWidth: width,
			color: '#373737',
		},
	});

	return column.style;
}

export function getColumnDataStyle(width: number | string) {
	const column = StyleSheet.create({
		style: {
			width: width,
			maxHeight: 60,
			color: '#474747',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	});

	return column.style;
}

export const ReportButton = styled.button`
	${OptionsButton}
	width: 14rem;
`;
