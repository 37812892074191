import styled from 'styled-components';

export const Container = styled.div`
	width: 50vw;
`;

export const OpenModalBtn = styled.button`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--primary-blue);
	background: none;
	width: fit-content;
`;

export const ContentInfo = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	margin: 2rem 0;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 1rem;
	text-transform: uppercase;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;
