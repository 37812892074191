import { Benefit } from '../../../../../@types/Benefit';
import { EmptyContent } from '../../../../../components/EmptyContent';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { sortBenefitsByAlfabeticalOrder } from '../../../../../utils/benefits/sortArrayByAlfabeticalOrder';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import * as S from '../styles';

interface Props {
	benefits: Benefit[];
}

export function CompanyEnabledBenefits({ benefits }: Props) {
	if (!benefits?.length) {
		return <EmptyContent big />;
	}

	return (
		<TableStyle.Table>
			<TableStyle.TableHeader>
				<TableStyle.TableRow>
					<TableStyle.TableHeaderCell>TÍTULO</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>VALOR PADRÃO</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>CRIAÇÃO</TableStyle.TableHeaderCell>
				</TableStyle.TableRow>
			</TableStyle.TableHeader>

			<TableStyle.TableBody>
				{sortBenefitsByAlfabeticalOrder(benefits)
					.filter((benefit) => benefit.status)
					.map((benefit) => (
						<TableStyle.TableRow key={benefit.id}>
							<TableStyle.TableData>
								<S.TitleContainer>
									{getBenefitIcon(benefit.title)}{' '}
									{getBenefitParsedTitle(benefit.title)}
								</S.TitleContainer>
							</TableStyle.TableData>
							<TableStyle.TableData>
								{Number(benefit.value_default) * 100}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{new Date(benefit.created_at!).toLocaleDateString()}
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
			</TableStyle.TableBody>
		</TableStyle.Table>
	);
}
