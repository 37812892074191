import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import { EmptyContent } from '../../../components/EmptyContent';
import { Filter } from '../../../components/Filter';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { Pagination } from '../../../components/Pagination';
import * as TableStyles from '../../../components/Table/TableStyles';
import { TextWithHint } from '../../../components/TextWithHint';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { getGroupCompanies } from '../../../services/queries/GroupCompanies';
import { useGroupCompaniesListStore } from '../../../stores/useGroupCompaniesListStore';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as S from './styles';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook/index';
import { SortColumnButton } from '../../../components/SortColumnButton';

export function GroupCompanies() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] =
		useGroupCompaniesListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);
	useSetHistoryNonMatchCallbackLocation('group-companies', resetFilters);

	const fetchGroupCompaniesQuery = useQuery(
		['groupCompaniesList', currentPage, filtersParams, currentSortColumn],
		() => {
			return getGroupCompanies(currentPage, filtersParams, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os grupos corporativos.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('groupCompaniesList');
	}

	if (fetchGroupCompaniesQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Grupos Corporativos' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Grupos Corporativos'
					totalRecords={fetchGroupCompaniesQuery.data?.totalCorpGroups}
				/>

				<Filter
					filterParams={filtersParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						// setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchGroupCompaniesQuery.data?.totalCorpGroups!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchGroupCompaniesQuery.data?.corpGroups.length! > 0 ? (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'name'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('name')}
								/>
								NOME
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'companies_quantity'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('companies_quantity')}
								/>
								Nº DE EMPRESAS
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'admin_email'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('admin_email')}
								/>
								ADMIN BENEFÍCIOS FLEXÍVEIS
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'general_admin'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('general_admin')}
								/>
								ADMIN CONTROL
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
								OPÇÕES
							</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchGroupCompaniesQuery.data?.corpGroups.map((corpGroup) => (
							<TableStyles.TableRow key={corpGroup.id}>
								<TableStyles.TableData>
									<TextWithHint text={corpGroup.name} width='18rem' />
								</TableStyles.TableData>
								<TableStyles.TableData>
									{corpGroup.companiesQuantity}
								</TableStyles.TableData>
								<TableStyles.TableData style={{ maxWidth: '24rem' }}>
									{corpGroup.admin_email ?? '-'}
								</TableStyles.TableData>
								<TableStyles.TableData style={{ maxWidth: '24rem' }}>
									{corpGroup.general_admin ?? '-'}
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TableStyles.Option
										data-rh='Detalhar empresas'
										to={`/group-companies/${corpGroup.id}`}
									>
										<MdNotes />
									</TableStyles.Option>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
