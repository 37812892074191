import { useState } from 'react';
import * as S from './styles';
import Modal from '../../../../../../components/Modal';
import PageTitle from '../../../../../../components/PageTitle';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import {
	MCCBenefits,
	getMCCDetails,
} from '../../../../../../services/queries/Transactions';
import Loader from '../../../../../../components/Loader';
import { getBenefitParsedTitle } from '../../../../../../utils/benefits/getBenefitParsedTitle';

interface Props {
	mccCode?: string;
}
export function MCCDetailsModal({ mccCode }: Props) {
	const [isOpen, setOpen] = useState(false);

	const fetchMCCDetailsQuery = useQuery(
		[mccCode, 'MCCDetails'],
		() => getMCCDetails(mccCode!),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o detalhamento do MCC.'
				);
			},
			enabled: isOpen && !!mccCode,
		}
	);

	function getMCCBenefitsList() {
		const benefits = [
			'transporte',
			'alimentação',
			'refeição',
			'saúde',
			'home_office',
			'cultura',
			'reembolso',
			'causas',
			'premio',
			'games',
			'viagens',
			'educação',
			'jus',
			'maternidade',
			'premio_saque',
			'reembolso_saque',
		];

		let includedBenefits: string[] = [];
		benefits.forEach((benefitTitle) => {
			if (fetchMCCDetailsQuery.data?.[benefitTitle as keyof MCCBenefits]) {
				includedBenefits.push(`${getBenefitParsedTitle(benefitTitle)}`);
			}
		});

		return includedBenefits.join(', ');
	}

	if (!fetchMCCDetailsQuery.data)
		return (
			<>
				<S.OpenModalBtn onClick={() => setOpen(true)} data-rh='Detalhar MCC'>
					{mccCode}
				</S.OpenModalBtn>

				<Modal
					isOpen={isOpen}
					onRequestClose={() => setOpen(false)}
					enableClose
				>
					<S.Container>
						<PageTitle title={`Detalhamento do MCC - ${mccCode}`} />
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	return (
		<>
			<S.OpenModalBtn onClick={() => setOpen(true)} data-rh='Detalhar MCC'>
				{mccCode}
			</S.OpenModalBtn>

			<Modal isOpen={isOpen} onRequestClose={() => setOpen(false)} enableClose>
				<S.Container>
					<PageTitle title={`Detalhamento do MCC - ${mccCode}`} />

					<S.ContentInfo>
						<S.InfoContainer>
							<S.InfoTitle>CÓDIGO DO MCC</S.InfoTitle>
							{fetchMCCDetailsQuery.data?.code}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>DESCRIÇÃO</S.InfoTitle>
							{fetchMCCDetailsQuery.data?.description}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>ID_Grupo do MCC</S.InfoTitle>
							{fetchMCCDetailsQuery.data?.id_group}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Descrição do Grupo</S.InfoTitle>
							{fetchMCCDetailsQuery.data?.group_description}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Descrição do extrato</S.InfoTitle>
							{fetchMCCDetailsQuery.data?.description_extract}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Carteiras do MCC</S.InfoTitle>
							{getMCCBenefitsList()}
						</S.InfoContainer>
					</S.ContentInfo>
				</S.Container>
			</Modal>
		</>
	);
}
