import { Link } from 'react-router-dom';
import logoImg from '../../assets/Bounty.svg';
import { FaBell, FaSignOutAlt } from 'react-icons/fa';
import * as S from './styles';
import { useAuth } from '../../hooks/useAuth';
import { HeaderPopover } from './HeaderPopover';
import { useState } from 'react';
import { NotificationsList } from '../NotificationList';
import { useNotifications } from '../../hooks/useNotifications';

export function Header() {
	const { user, signOut } = useAuth();
	const { unreadNotifications } = useNotifications();
	const [isNotificationDropdownShowing, setIsNotificationDropdownShowing] =
		useState(false);

	return (
		<S.Container>
			<div>
				<Link to='/'>
					<S.LogoImage src={logoImg} alt='Logo do Bounty' />
				</Link>
			</div>

			<div>
				<HeaderPopover // NOTIFICATIONS DROPDOWN
					isOpen={isNotificationDropdownShowing}
					onClose={() => setIsNotificationDropdownShowing(false)}
					content={() => (
						<S.DropdownNotificationsContainer>
							<NotificationsList open={isNotificationDropdownShowing} />
						</S.DropdownNotificationsContainer>
					)}
				>
					<S.IconBtn
						onClick={() =>
							setIsNotificationDropdownShowing(!isNotificationDropdownShowing)
						}
					>
						<FaBell />{' '}
						{!!unreadNotifications && (
							<span
								data-rh={
									unreadNotifications > 99
										? `${unreadNotifications}`
										: undefined
								}
							>
								{unreadNotifications > 99 ? '99+' : unreadNotifications}
							</span>
						)}
					</S.IconBtn>
				</HeaderPopover>

				<S.UserEmail>{user.email}</S.UserEmail>

				<S.ProfileOptionButton onClick={signOut}>
					<FaSignOutAlt />
					Sair
				</S.ProfileOptionButton>
			</div>
		</S.Container>
	);
}
