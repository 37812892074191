import { Transaction } from '../../@types/CorporateExpenses/Transaction';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import api from '../api';

export const CORPWAY_EXTRACT_KEY = 'CORPWAY_EXTRACT_SERVICE_KEY';

export async function getExtract(
	cardId: string,
	companyId: string,
	filterParams: FilterParams[]
) {
	const { start_date, end_date } = parseFilterParamsToParams(filterParams);
	const start_date_parsed = new Date(start_date).valueOf();
	const end_date_parsed = new Date(end_date).valueOf();

	const { data } = await api.get<Transaction[]>(
		`api/v1/rh/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			params: {
				start_date: start_date_parsed,
				end_date: end_date_parsed,
			},
		}
	);
	return data;
}

export async function getCardExtractByMonth(
	cardId: string,
	companyId: string,
	monthDate: string,
	activeViewer: boolean,
	filterParams: FilterParams[]
) {
	const [year, month] = monthDate.split('-').map(Number);

	const start_date_parsed = new Date(year, month - 1, 1).valueOf();
	const end_date_parsed = new Date(year, month, 0).valueOf();

	const { data } = await api.get<Transaction[]>(
		`/api/v1/cs/corpway/companies/${companyId}/cards/${cardId}/extract`,
		{
			params: {
				start_date: start_date_parsed,
				end_date: end_date_parsed,
				activeViewer,
				...parseFilterParamsToParams(filterParams),
			},
		}
	);
	return data;
}
