import { Company } from '../../../../@types/Company';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { EmptyContent } from '../../../../components/EmptyContent';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { TextWithHint } from '../../../../components/TextWithHint';
import { cnpjMask } from '../../../../utils/masks/cnpj';
import { parseKYCStatusString } from '../../../../utils/status/parseKycStatus';
import { parseStatus } from '../../../../utils/status/parseStatus';
import { CompanyDetailsModal } from '../../Companies/CompanyDetailsModal';
import * as S from './styles';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook/index';

interface Props {
	companies: Company[];
}
export function CompaniesTab({ companies }: Props) {
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	if (companies.length === 0) return <EmptyContent big />;

	return (
		<TableStyles.Table>
			<TableStyles.TableHeader>
				<TableStyles.TableRow>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'name'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('name')}
						/>
						NOME
					</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>CNPJ</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'email'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('email')}
						/>
						EMAIL
					</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>KYC STATUS</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
						OPÇÕES
					</TableStyles.TableHeaderCell>
				</TableStyles.TableRow>
			</TableStyles.TableHeader>

			<TableStyles.TableBody>
				{sortList(companies).map((company) => (
					<TableStyles.TableRow key={company.id}>
						<TableStyles.TableData>
							<TextWithHint text={company.name} width='28rem' />
						</TableStyles.TableData>
						<TableStyles.TableData>
							<S.cnpjText>{cnpjMask(company.cnpj)}</S.cnpjText>
						</TableStyles.TableData>
						<TableStyles.TableData>
							<TextWithHint text={company.email} width='28rem' />
						</TableStyles.TableData>
						<TableStyles.TableData>
							{parseKYCStatusString(company.kyc_status)}
						</TableStyles.TableData>
						<TableStyles.TableData>
							{parseStatus(company.status)}
						</TableStyles.TableData>
						<TableStyles.TableData>
							<S.OptionsContainer>
								<CompanyDetailsModal company={company} />
							</S.OptionsContainer>
						</TableStyles.TableData>
					</TableStyles.TableRow>
				))}
			</TableStyles.TableBody>
		</TableStyles.Table>
	);
}
