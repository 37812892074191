import { useState } from 'react';
import { CollaboratorsSelector } from './components/CollaboratorsSelector';
import { Collaborator } from '../../../../@types/Collaborators';
import UserCard from '../../../../components/UserCard';
import { Company } from '../../../../@types/Company';
import { CompanySelector } from './components/CompanySelector';
import { Input, InputTextArea } from '../../../../components/Form/FormStyles';
import { useForm } from 'react-hook-form';
import * as S from './styles';
import PageTitle from '../../../../components/PageTitle';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import {
	MigrateCollaboratorRequest,
	migrateCollaborator,
} from '../../../../services/queries/CollaboratorsMigrations';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/Loader';

type FormData = { new_email?: string; reason_for_migration: string };

export function CreateCollaboratorMigration() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [selectedCollaborator, setSelectedCollaborator] =
		useState<Collaborator | null>(null);
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const [selectedCurrentCompany, setSelectedCurrentCompany] =
		useState<Company | null>(null);
	const { register, handleSubmit } = useForm<FormData>({
		defaultValues: {
			new_email: '',
			reason_for_migration: '',
		},
	});

	const createMigrationMutation = useMutation(
		(req: MigrateCollaboratorRequest) => migrateCollaborator(req),
		{
			onSuccess: () => {
				toast.info('Migração criada com sucesso!');
				queryClient.invalidateQueries(['collaboratorsMigrations']);
				navigate('/collaborators-migrations');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar a migração.'
				);
			},
		}
	);

	function handleCreateMigration(d: FormData) {
		if (!selectedCollaborator || !selectedCompany || !selectedCurrentCompany) {
			toast.error(
				'Selecione um colaborador e uma empresa destino para criar a migração.'
			);
			return;
		}

		createMigrationMutation.mutate({
			collaborator: {
				id: selectedCollaborator.id,
				new_email: d.new_email ? d.new_email : selectedCollaborator.email,
			},
			current_company_id: selectedCurrentCompany.id,
			new_company_id: selectedCompany.id,
			reason_for_migration: d.reason_for_migration,
		});
	}

	return (
		<form onSubmit={handleSubmit(handleCreateMigration)}>
			<PageTitle title='Criar migração' />
			<S.OptionsContainer>
				<S.OptionSection>
					<S.OptionTitle>Antiga empresa</S.OptionTitle>
					{selectedCurrentCompany ? (
						<UserCard
							name={selectedCurrentCompany.name}
							fontColor='#929292'
							fontSize='1.6rem'
						/>
					) : (
						<S.NoOptionSelectedMessage>
							Nenhuma empresa selecionada
						</S.NoOptionSelectedMessage>
					)}
					<CompanySelector
						selectedCompany={selectedCurrentCompany}
						onSelectCompany={(c) => {
							setSelectedCurrentCompany(c);
						}}
					/>
				</S.OptionSection>

				<S.OptionSection>
					<S.OptionTitle>Colaborador</S.OptionTitle>
					{selectedCollaborator ? (
						<UserCard
							name={`${selectedCollaborator.first_name} ${selectedCollaborator.last_name}`}
							companyName={selectedCollaborator.company_name}
							fontColor='#929292'
							fontSize='1.6rem'
						/>
					) : (
						<S.NoOptionSelectedMessage>
							Nenhum colaborador selecionado
						</S.NoOptionSelectedMessage>
					)}

					<CollaboratorsSelector
						selectedCollaborator={selectedCollaborator}
						onSelectCollaborator={(c) => {
							setSelectedCollaborator(c);
						}}
						isDisable={selectedCurrentCompany !== null ? false : true}
						company={selectedCurrentCompany}
					/>
				</S.OptionSection>

				<S.OptionSection>
					<S.OptionTitle>Nova empresa</S.OptionTitle>
					{selectedCompany ? (
						<UserCard
							name={selectedCompany.name}
							fontColor='#929292'
							fontSize='1.6rem'
						/>
					) : (
						<S.NoOptionSelectedMessage>
							Nenhuma empresa selecionada
						</S.NoOptionSelectedMessage>
					)}
					<CompanySelector
						selectedCompany={selectedCompany}
						onSelectCompany={(c) => {
							setSelectedCompany(c);
						}}
						isDisable={
							selectedCollaborator && selectedCurrentCompany !== null
								? false
								: true
						}
					/>
				</S.OptionSection>
			</S.OptionsContainer>

			<S.InputsContainer>
				<S.Inputs>
					<div>
						<S.OptionTitle htmlFor='new_email'>
							Novo email do colaborador{' '}
							<S.NoObrigatoryIndicator>*</S.NoObrigatoryIndicator>
						</S.OptionTitle>
						<Input
							type='email'
							id='new_email'
							{...register('new_email')}
							disabled={selectedCompany === null ? true : false}
							style={{
								cursor: selectedCompany === null ? 'not-allowed' : 'auto',
								backgroundColor:
									selectedCompany === null ? '#f5f5f5' : 'f0f0f0',
							}}
						/>
						<S.Warning>
							Novo e-mail cadastrado será utilizado para o login
						</S.Warning>
					</div>
					<div>
						<S.OptionTitle htmlFor='reason_for_migration'>
							Motivo da migração{' '}
							<S.ObrigatoryIndicator>*</S.ObrigatoryIndicator>
						</S.OptionTitle>
						<InputTextArea
							id='reason_for_migration'
							required
							{...register('reason_for_migration')}
							disabled={selectedCompany === null ? true : false}
							style={{
								cursor: selectedCompany === null ? 'not-allowed' : 'auto',
								backgroundColor:
									selectedCompany === null ? '#f5f5f5' : 'f0f0f0',
							}}
						/>
					</div>
				</S.Inputs>
				<div>
					<S.ObrigatoryIndicator>* Campo obrigatório</S.ObrigatoryIndicator>
				</div>
				<div>
					<S.NoObrigatoryIndicator>* Campo opcional</S.NoObrigatoryIndicator>
				</div>
			</S.InputsContainer>

			<S.Submit
				type='submit'
				disabled={
					createMigrationMutation.isLoading || selectedCompany === null
						? true
						: false
				}
			>
				{createMigrationMutation.isLoading ? (
					<Loader size={14} color='#FFF' />
				) : (
					'Criar migração'
				)}
			</S.Submit>
		</form>
	);
}
