import { useEffect, useState } from 'react';
import { Collaborator } from '../../../../../../@types/Collaborators';
import { useQuery } from 'react-query';
import { getCollaboratorsByCompany } from '../../../../../../services/queries/Collaborators';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import * as S from './styles';
import { Pagination } from '../../../../../../components/Pagination';
import PageTitle from '../../../../../../components/PageTitle';
import * as TableStyles from '../../../../../../components/Table/TableStyles';
import Modal from '../../../../../../components/Modal';
import Loader from '../../../../../../components/Loader';
import { cpfMask } from '../../../../../../utils/masks/cpf';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { RiAddCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { filterParams as collaboratorsFilterParams } from '../../../../../../stores/useCollaboratorsListStore';
import { Filter, FilterParams } from '../../../../../../components/Filter';
import { Company } from '../../../../../../@types/Company';

interface Props {
	selectedCollaborator: Collaborator | null;
	onSelectCollaborator: (collaborator: Collaborator | null) => void;
	isDisable?: boolean;
	company: Company | null;
}

export function CollaboratorsSelector({
	selectedCollaborator,
	onSelectCollaborator,
	isDisable,
	company,
}: Props) {
	const [isOpen, setOpen] = useState(false);
	const [collaboratorsCurrentPage, setCollaboratorsCurrentPage] = useState(1);
	const [filterParams, setFilterParams] = useState(collaboratorsFilterParams);

	const fetchCollaboratorsQuery = useQuery(
		// ['collaboratorsList', collaboratorsCurrentPage, filterParams, currentSortColumn],
		['collaboratorsList', collaboratorsCurrentPage, filterParams],
		() => {
			if (!company?.id) {
				throw new Error('Company ID is not defined');
			}
			return getCollaboratorsByCompany(
				company.id,
				collaboratorsCurrentPage,
				filterParams,
				null
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os colaboradores.'
				);
			},

			enabled: isOpen,
		}
	);

	useEffect(() => {
		if (isOpen) {
			// reseting
			setCollaboratorsCurrentPage(1);
		}
	}, [isOpen]);

	function handleClose() {
		setOpen(false);
	}

	function updateFilters(filters: FilterParams[]) {
		setCollaboratorsCurrentPage(1);
		setFilterParams(filters);
	}

	function handleToggleSelectCollaborator(collaborator: Collaborator | null) {
		onSelectCollaborator(collaborator);
		handleClose();
	}

	if (!fetchCollaboratorsQuery.data) {
		return (
			<div>
				<S.MainButton
					onClick={() => setOpen(true)}
					type='button'
					disabled={isDisable}
				>
					Selecionar Colaborador
				</S.MainButton>
				<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
					<S.Container data-testid='CollabsSelector-modal-container'>
						<TableStyles.TableHeaderContainer>
							<PageTitle title='Selecione o colaborador' />
						</TableStyles.TableHeaderContainer>
						<Loader />
					</S.Container>
				</Modal>
			</div>
		);
	}
	return (
		<div>
			<S.MainButton
				onClick={() => setOpen(true)}
				type='button'
				disabled={isDisable}
			>
				Selecionar Colaborador
			</S.MainButton>
			<Modal isOpen={isOpen} enableClose onRequestClose={handleClose}>
				<S.Container data-testid='CollabsSelector-modal-container'>
					<TableStyles.TableHeaderContainer>
						<PageTitle title='Selecione o colaborador' />
						<Filter
							filterParams={filterParams}
							onFiltersChanged={updateFilters}
						/>
						<Pagination
							onPageChange={(page) => setCollaboratorsCurrentPage(page)}
							currentPage={collaboratorsCurrentPage}
							totalCount={fetchCollaboratorsQuery.data.totalCount}
						/>
					</TableStyles.TableHeaderContainer>
					<S.CollaboratorsList>
						{fetchCollaboratorsQuery.data.collaborators.map((collaborator) => (
							<S.CollaboratorContainer key={collaborator.id}>
								<S.CollaboratorCard>
									<S.CollaboratorInfoContainer>
										<S.CollaboratorName>{`${collaborator.first_name} ${collaborator.last_name}`}</S.CollaboratorName>
										<S.CollaboratorInfo>
											{collaborator.office}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{cpfMask(collaborator.cpf)}
										</S.CollaboratorInfo>
										<S.CollaboratorInfo>
											{collaborator.email}
										</S.CollaboratorInfo>
									</S.CollaboratorInfoContainer>
								</S.CollaboratorCard>

								{selectedCollaborator?.id === collaborator.id ? (
									<S.Selector
										selected
										onClick={() => handleToggleSelectCollaborator(null)}
									>
										<RiCloseCircleLine />
									</S.Selector>
								) : (
									<S.Selector
										selected={false}
										onClick={() => handleToggleSelectCollaborator(collaborator)}
									>
										<RiAddCircleLine />
									</S.Selector>
								)}
							</S.CollaboratorContainer>
						))}

						{fetchCollaboratorsQuery.data.collaborators.length === 0 && (
							<EmptyContent text='Nenhum colaborador disponível' />
						)}
					</S.CollaboratorsList>
				</S.Container>
			</Modal>
		</div>
	);
}
