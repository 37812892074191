import styled from 'styled-components';
import { OptionsButton } from '../../../../../../components/Button';

export const GridContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 1rem;
`;

export const InfoLabel = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--light-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const DuplicateCard = styled.h1`
	font-weight: 400;
	font-size: 1.5rem;
	text-transform: uppercase;
`;

export const SendEmailBtn = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 4rem;
	width: fit-content;
`;

export const CopyIdBtn = styled.button`
	${OptionsButton}
	margin-top: 2rem;
	height: 4rem;
	width: fit-content;
`;

export const BttnContainer = styled.div`
	padding-bottom: 2rem;
	display: flex;
	column-gap: 1rem;
`;
