import styled from 'styled-components';

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	width: contain;
	padding: 0.8rem;
	margin: auto;
	margin-left: auto;
	color: var(--white);
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 25rem;
	height: 20rem;
	padding: 2rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	gap: 2rem;
	padding-top: 2rem;
	font-size: 1.5rem;
	width: 19rem;
	justify-content: space-between;
`;
export const TitleContainer = styled.div`
	margin-bottom: 1rem;
	flex-direction: row;
	display: flex;
	align-items: center;
	gap: 2rem;
`;
export const Title = styled.h1`
	font-size: 2.5rem;
`;
export const Label = styled.p`
	color: var(--light-gray);
`;
export const Content = styled.span``;
