import { useState } from 'react';
import { FaCreditCard } from 'react-icons/fa';
import { CardInfo } from '../../../../../@types/Collaborators';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import {
	parseCardType,
	parseLinkedStatus,
	parseStatus,
} from '../../../../../utils/status/parseStatus';
import * as S from './styles';

interface Props {
	card?: CardInfo;
}
export function CardDetailsModal({ card }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	if (!card)
		return (
			<S.openModalBttn
				disabled={!card} // button is disabled if card is undefined
				data-rh='Cartão'
				data-testid='openButton_test_id'
			>
				<FaCreditCard />
			</S.openModalBttn>
		);

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-rh='Cartão'
				data-testid='openButton_test_id'
			>
				<FaCreditCard />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<PageTitle title={`Dados do cartão`} />

					<S.DataContainer>
						<S.InfoContainer>
							<S.InfoLabel>Tipo de cartão</S.InfoLabel>
							<S.InfoContent>{parseCardType(card.card_type!)}</S.InfoContent>
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoLabel>Status</S.InfoLabel>
							<S.InfoContent>{parseStatus(card.is_active!)}</S.InfoContent>
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoLabel>Status de vínculo</S.InfoLabel>
							<S.InfoContent>{parseLinkedStatus(card.linked!)}</S.InfoContent>
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoLabel>Aproximação</S.InfoLabel>
							<S.InfoContent>
								{parseStatus(card.contactless_active!)}
							</S.InfoContent>
						</S.InfoContainer>
						<S.InfoContainer>
							<S.InfoLabel>ID</S.InfoLabel>
							<S.InfoContent>{card.id_baas}</S.InfoContent>
						</S.InfoContainer>
					</S.DataContainer>
				</S.Container>
			</Modal>
		</>
	);
}
