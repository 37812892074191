import { FaWallet } from 'react-icons/fa';
import alimentacao from '../../assets/benefits/alimentacao.svg';
import causas from '../../assets/benefits/causas.svg';
import cultura from '../../assets/benefits/cultura.svg';
import educacao from '../../assets/benefits/educacao.svg';
import games from '../../assets/benefits/games.svg';
import maternidade from '../../assets/benefits/maternidade.svg';
import home from '../../assets/benefits/home.svg';
import jus from '../../assets/benefits/jus.svg';
import premio from '../../assets/benefits/premio.svg';
import refeicao from '../../assets/benefits/refeicao.svg';
import saude from '../../assets/benefits/saude.svg';
import trans from '../../assets/benefits/trans.svg';
import viagem from '../../assets/benefits/viagem.svg';

export function getBenefitIcon(benefitTitle: string) {
	switch (benefitTitle) {
		case 'ALIMENTAÇÃO':
			return <img src={alimentacao} alt={benefitTitle} />;
		case 'PREMIO':
			return <img src={premio} alt={benefitTitle} />;
		case 'PREMIAÇÃO':
			return <img src={premio} alt={benefitTitle} />;
		case 'PREMIO_SAQUE':
			return <img src={premio} alt={benefitTitle} />;
		case 'PREMIAÇÃO COM SAQUE':
			return <img src={premio} alt={benefitTitle} />;
		case 'CAUSAS':
			return <img src={causas} alt={benefitTitle} />;
		case 'DOAÇÃO E CAUSAS':
			return <img src={causas} alt={benefitTitle} />;
		case 'REFEIÇÃO':
			return <img src={refeicao} alt={benefitTitle} />;
		case 'TRANSPORTE':
			return <img src={trans} alt={benefitTitle} />;
		case 'SAÚDE':
			return <img src={saude} alt={benefitTitle} />;
		case 'HOME_OFFICE':
			return <img src={home} alt={benefitTitle} />;
		case 'HOME OFFICE':
			return <img src={home} alt={benefitTitle} />;
		case 'JUS':
			return <img src={jus} alt={benefitTitle} />;
		case 'ASSISTÊNCIA JURÍDICA':
			return <img src={jus} alt={benefitTitle} />;
		case 'CULTURA':
			return <img src={cultura} alt={benefitTitle} />;
		case 'EDUCAÇÃO':
			return <img src={educacao} alt={benefitTitle} />;
		case 'VIAGENS':
			return <img src={viagem} alt={benefitTitle} />;
		case 'GAMES':
			return <img src={games} alt={benefitTitle} />;
		case 'MATERNIDADE':
			return <img src={maternidade} alt={benefitTitle} />;
		case 'MATERNIDADE E PATERNIDADE':
			return <img src={maternidade} alt={benefitTitle} />;

		default:
			return <FaWallet />;
	}
}
