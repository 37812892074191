import {
	Document,
	Page,
	Text,
	View,
	Font,
	pdf,
	Image,
} from '@react-pdf/renderer';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FilterParams } from '../../../../components/Filter';
import Loader from '../../../../components/Loader';
import {
	PDFstyles,
	ReportButton,
} from '../../../../components/PDFReportStyles';
import { SortColumn } from '../../../../hooks/useSortColumnHook';
import { getOperationalReportToPDF } from '../../../../services/queries/OperationalReport';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { cpfMask } from '../../../../utils/masks/cpf';
import { parseCardStatus } from '../util/parseCardStatus';
import BountyLogo from '../../../../assets/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../assets/ReportIcon.png';

Font.register({
	family: 'Poppins',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf',
		},
	],
});

interface ReportProps {
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
}

export function ReportGenerator({ filterParams, sortColumn }: ReportProps) {
	// const { user } = useAuth();

	const fetchOperationalReportsQuery = useQuery(
		['operationalReportToPDF', filterParams, sortColumn],
		() => {
			return getOperationalReportToPDF(filterParams, sortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o relatorio operacional.'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = 'relatorio_operacional.pdf';

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchOperationalReportsQuery.refetch();
		const { totalCount, collaborators, totalReports } = reportFetching.data!;

		let filters: { name: string; value: string; second_value?: string }[] = [];

		filterParams.forEach((filter) => {
			if (filter.selected) {
				filters.push({
					name: filter.name,
					value: filter.value,
					second_value: filter.second_value,
				});
			}
		});

		return (
			<Document>
				<Page size='A4' style={PDFstyles.page} orientation='landscape'>
					<View style={PDFstyles.header} fixed>
						<Image src={BountyLogo} style={PDFstyles.logo} />
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>N° Página:</Text>
							<Text
								style={PDFstyles.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							></Text>
						</View>
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>Data do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false,
								}).format(new Date())}
							</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '120%' }}>
							<Text style={PDFstyles.headerInfoLabel}>Intervalo de busca:</Text>
							<Text style={PDFstyles.headerInfoData}>Todo o período</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '60%' }}>
							<Text style={PDFstyles.headerInfoLabel}>N° do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={PDFstyles.subHeader}>
						<View style={PDFstyles.titleContainer}>
							<Image src={ReportIcon} style={PDFstyles.reportLogo} />
							<View style={PDFstyles.reportTitleContainer}>
								<Text style={{ ...PDFstyles.reportTitle, color: '#FF0037' }}>
									Relatório
								</Text>

								<Text> </Text>

								<Text style={PDFstyles.reportTitle}>Operacional</Text>
							</View>
						</View>

						<View style={PDFstyles.subHeaderDataContainer}>
							<View style={{ ...PDFstyles.subHeaderInfo }}>
								<Text style={PDFstyles.subHeaderLabel}>
									Total de registros:
								</Text>
								<Text> </Text>
								<Text style={PDFstyles.subHeaderData}>{totalCount}</Text>
							</View>
						</View>
					</View>

					<View style={PDFstyles.tableContainer}>
						<View style={PDFstyles.tableHeader}>
							<View
								style={{
									...PDFstyles.columnTitleContainer,
									width: '20%',
								}}
							>
								<Text style={PDFstyles.columnTitle}>Nº</Text>
							</View>
							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Nome</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>CPF</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Próximo agendamento</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Última recarga</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Última transação</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Status do cartão</Text>
							</View>
						</View>

						{collaborators.map((c, index) => (
							<View style={PDFstyles.tableRow} key={index} wrap={false}>
								<Text
									style={{
										...PDFstyles.columnData,
										width: '20%',
									}}
								>
									{String(index + 1)}
								</Text>
								<Text style={PDFstyles.columnData}>
									{`${c.first_name} ${c.last_name}`}
								</Text>

								<Text style={PDFstyles.columnData}>{cpfMask(c.cpf)}</Text>

								<Text style={PDFstyles.columnData}>
									{c.scheduled_date
										? new Date(c.scheduled_date).toLocaleString()
										: 'Sem agendamento'}
								</Text>

								<Text style={PDFstyles.columnData}>
									{c.release_date
										? new Date(c.release_date).toLocaleString()
										: 'Sem recarga anterior'}
								</Text>

								<Text style={PDFstyles.columnData}>
									{c.transaction_date
										? new Date(c.transaction_date).toLocaleString()
										: 'Sem transação executada'}
								</Text>

								<Text style={PDFstyles.columnData}>
									{parseCardStatus(c.card_status)}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<ReportButton onClick={handleGeneratePDF}>
				{fetchOperationalReportsQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório'
				)}
			</ReportButton>
		</>
	);
}
