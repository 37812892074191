import { useState, ComponentType } from 'react';
import * as S from './styles';
import { IconBaseProps } from 'react-icons';
import { SidebarLink } from '../SidebarLink';
import { useLocation } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';

interface MenuItem {
	title: string;
	icon: ComponentType<IconBaseProps>;
	path: string;
}

interface Props extends Omit<MenuItem, 'path'> {
	subMenuItems: MenuItem[];
}

export function SidebarDropdown({ title, icon: Icon, subMenuItems }: Props) {
	const [isOpen, setOpen] = useState(false);
	//const { path } = useRouteMatch();
	const { pathname } = useLocation();

	const toggleOpen = () => setOpen((state) => !state);
	const currentMenuItem = subMenuItems.find((item) =>
		pathname.includes(item.path)
	);

	return (
		<>
			<S.OpenButtonContainer onClick={toggleOpen}>
				<Icon />
				<S.Title>{title}</S.Title>
				<S.DropdownIndicatorContainer>
					{isOpen ? <FaMinus /> : <FaPlus />}
				</S.DropdownIndicatorContainer>
			</S.OpenButtonContainer>

			{isOpen && (
				<S.SubMenuItemsContainer>
					{subMenuItems.map((item) => (
						<SidebarLink
							icon={item.icon}
							title={item.title}
							path={`${item.path}`}
							currentPage={item === currentMenuItem}
							key={item.title + item.path}
						/>
					))}
				</S.SubMenuItemsContainer>
			)}
		</>
	);
}
