import { Route, Routes as RouterRoutes } from 'react-router-dom';
import { Main } from '../pages/Main';
import SignIn from '../pages/SignIn';
import { RequireAuth } from './RequireAuth';
import NotFound from '../pages/NotFound';

export default function Routes() {
	return (
		<RouterRoutes>
			<Route
				path='*'
				element={
					<RequireAuth>
						<Main />
					</RequireAuth>
				}
			/>

			<Route path='/session' element={<SignIn />} />

			<Route path='/404' element={<NotFound />} />
		</RouterRoutes>
	);
}
