import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Loader from '../../../components/Loader';
import PageTitle from '../../../components/PageTitle';
import { useSetHistoryNonMatchCallbackLocation } from '../../../hooks/useSetHistoryNonMatchCallbackLocation';
import { getReleases } from '../../../services/queries/Releases';
import { useReleasesListStore } from '../../../stores/useReleasesListStore';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import * as S from './styles';
import * as TableStyles from '../../../components/Table/TableStyles';
import { Filter } from '../../../components/Filter';
import { Pagination } from '../../../components/Pagination';
import { EmptyContent } from '../../../components/EmptyContent';
import { cnpjMask } from '../../../utils/masks/cnpj';
import { convertCentsToReais } from '../../../utils/CurrencyConvert';
import {
	getPaymentStatusIcon,
	parsePaymentStatus,
} from '../../../utils/status/parsePaymentStatus';
import { useSortColumnHook } from '../../../hooks/useSortColumnHook/index';
import { SortColumnButton } from '../../../components/SortColumnButton';
import { MdNotes } from 'react-icons/md';

export function Releases() {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams, resetFilters] = useReleasesListStore(
		(state) => [state.filterParams, state.setFiltersParams, state.resetFilters]
	);
	useSetHistoryNonMatchCallbackLocation('releases', resetFilters);

	const fetchReleasesQuery = useQuery(
		['releasesList', currentPage, filtersParams, currentSortColumn],
		() => {
			return getReleases(currentPage, filtersParams, currentSortColumn);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os pagamentos.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('releasesList');
	}

	if (fetchReleasesQuery.isLoading || !fetchReleasesQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Pagamentos' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title='Pagamentos'
					totalRecords={fetchReleasesQuery.data?.totalReleases}
				/>

				<Filter
					filterParams={filtersParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				{/* Pagination spacing placeholder */}
				<div style={{ marginLeft: 'auto' }}></div>
				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchReleasesQuery.data?.totalReleases!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchReleasesQuery.data.releases.length > 0 ? (
				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'group_company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('group_company')}
								/>
								GRUPO CORPORATIVO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'company'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('company')}
								/>
								EMPRESA
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>CNPJ</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'total_value'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('total_value')}
								/>
								VALOR
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA CRIAÇÃO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'scheduled_payment_date'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('scheduled_payment_date')}
								/>
								DATA EXECUÇÃO
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>STATUS</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
								OPÇÕES
							</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{fetchReleasesQuery.data.releases.map((release) => (
							<TableStyles.TableRow key={release.id}>
								<TableStyles.TableData>
									{release.corporate_group.name}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{release.company.name}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{cnpjMask(release.company.cnpj)}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{convertCentsToReais(Number(release.total_value))}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{new Date(release.created_at).toLocaleDateString()}
								</TableStyles.TableData>
								<TableStyles.TableData>
									{release.scheduledDate
										? new Date(release.scheduledDate).toLocaleString()
										: 'NÃO AGENDADO'}
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TableStyles.StatusContainer>
										{getPaymentStatusIcon(release.status)}
										{parsePaymentStatus(release.status)}
									</TableStyles.StatusContainer>
								</TableStyles.TableData>
								<TableStyles.TableData>
									<TableStyles.Option
										data-rh='Detalhar pagamento'
										to={`/releases/${release.id}`}
									>
										<MdNotes />
									</TableStyles.Option>
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			) : (
				<EmptyContent big />
			)}
		</S.Container>
	);
}
