import { RequestDeleteUsersCollaborator } from '../../@types/RequestDeleteUsersCollaborator';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface RequestResponse {
	requests: RequestDeleteUsersCollaborator[];
	totalRequests: number;
}
export async function getRequestPendingDeleteUsersCollaborator(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<RequestResponse>(
		`/api/v1/cs/collaborators/requests-pending`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getRequestAcceptsDeleteUsersCollaborator(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<RequestResponse>(
		`/api/v1/cs/collaborators/requests-accepts`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function deleteUserCollaborator(
	user_id: string,
	company_id: string,
	request_id: string,
	deletionReason: string,
	reasonReviwed: string
): Promise<void> {
	const { data } = await api.put(`/api/v1/cs/requests/delete`, {
		user_id,
		company_id,
		request_id,
		deletionReason,
		reasonReviwed,
	});

	return data;
}
