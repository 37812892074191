import { useState } from 'react';
import * as S from './styles';
import Modal from '../../../../../components/Modal';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';

interface Props {
	data: any;
}

export function MovementsModal({ data }: Props) {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<S.openModalBttn onClick={() => setOpen(true)}>
				Movimentações
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				enableClose={true}
			>
				<S.Container>
					<S.TitleContainer>
						<S.Title>Movimentações</S.Title>
					</S.TitleContainer>
					<S.InfoContainer>
						<S.Label>Saldo atual:</S.Label>
						<S.Content>
							{convertCentsToReais(data?.card_balance_total)}
						</S.Content>
					</S.InfoContainer>
					<S.InfoContainer>
						<S.Label>Crédito:</S.Label>
						<S.Content>{convertCentsToReais(data?.totalCredits)}</S.Content>
					</S.InfoContainer>
					<S.InfoContainer>
						<S.Label>Débito:</S.Label>
						<S.Content>{convertCentsToReais(data?.totalDebits)}</S.Content>
					</S.InfoContainer>
				</S.Container>
			</Modal>
		</>
	);
}
