import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import { GroupCompany } from '../../../../@types/GroupCompany';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { fetchGroupCompany } from '../../../../services/queries/GroupCompanies';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';
import * as TabStyle from '../../../../components/Tab/TabStyles';
import { CompaniesTab } from './CompaniesTab';
import { OperatorsTab } from './OperatorsTab';

export function GroupCompanyDetails() {
	const { id } = useParams();

	const fetchGroupCompanyQuery = useQuery<GroupCompany, Error>(
		['fetchGroupCompany', id],
		() => {
			return fetchGroupCompany(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar informações do grupo corporativo. '
				);
			},
		}
	);

	if (fetchGroupCompanyQuery.isLoading || !fetchGroupCompanyQuery.data) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do Grupo Corporativo' />
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<S.TitleWrapper>
				<PageTitle
					title={`Detalhamento de grupo corporativo - ${fetchGroupCompanyQuery.data.name}`}
				/>
			</S.TitleWrapper>

			<Tabs>
				<TabStyle.TabListContainer>
					<TabStyle.TabCustomized>Empresas</TabStyle.TabCustomized>
					<TabStyle.TabCustomized>Operadores</TabStyle.TabCustomized>
				</TabStyle.TabListContainer>

				<TabPanel>
					<CompaniesTab companies={fetchGroupCompanyQuery.data.companies} />
				</TabPanel>

				<TabPanel>
					<OperatorsTab operators={fetchGroupCompanyQuery.data.operators} />
				</TabPanel>
			</Tabs>
		</S.Container>
	);
}
