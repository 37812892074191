import { toast } from 'react-toastify';
import Loader from '../../../../../components/Loader';
import { ReportButton } from '../../../../../components/PDFReportStyles';
import { useAuth } from '../../../../../hooks/useAuth';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import { getCollaboratorTransactionsV3ToReport } from '../../../../../services/queries/Collaborators';
import { FilterParams } from '../../../../../components/Filter';
import { SortColumn } from '../../../../../hooks/useSortColumnHook';
import RESPONSE_CODES from '../../Transactions/constants/RESPONSE_CODE.json';
import { parseTransactionStatus } from '../../../../../utils/status/parseStatus';
import { convertCentsToCurrency } from '../../../../../utils/CurrencyConvert';
import * as Excel from 'exceljs';
import { getTransactionType } from '../../../../../utils/transactionUtils';

export interface ReportProps {
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
	collaborator: {
		collaborator_name: string;
		collaborator_id: string;
		collaborator_cpf: string;
	};
}

export function SpreadsheetReportGenerator({
	collaborator,
	filterParams,
	sortColumn,
}: ReportProps) {
	const { user } = useAuth();

	const fetchOperationalReportsQuery = useQuery(
		['operationalReportToExcel', collaborator, filterParams, sortColumn],
		() => {
			return getCollaboratorTransactionsV3ToReport(
				collaborator.collaborator_id,
				filterParams,
				sortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações para o relatório.'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGenerateXLSX() {
		try {
			let blob: Blob;
			blob = await generateSheetContent();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_transações_${collaborator.collaborator_name}.xlsx`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivos de relatório. Tente novamente'
			);
		}
	}

	async function generateSheetContent() {
		const workbook = new Excel.Workbook();

		const worksheet = workbook.addWorksheet('Relatório de boletos');

		let columns = [
			{ header: 'CATEGORIA', key: 'category', width: 30 },
			{ header: 'MCC', key: 'mcc', width: 30 },
			{ header: 'STATUS', key: 'status', width: 30 },
			{ header: 'CÓDIGO DE STATUS', key: 'status_code', width: 20 },
			{ header: 'ESTABELECIMENTO', key: 'merchant_name', width: 20 },
			{ header: 'DATA DA TENTATIVA', key: 'date', width: 20 },
			{ header: 'VALOR', key: 'amount', width: 20 },
			{ header: 'CARTÃO', key: 'card', width: 20 },
		];

		// headers
		worksheet.columns = columns;

		const reportFetching = await fetchOperationalReportsQuery.refetch();
		const { transactions } = reportFetching.data!;

		transactions.forEach((transaction) => {
			const row = {
				category: getTransactionType(transaction.txn_type, transaction.msg_type),
				mcc: transaction.mcc ?? 'N/A',
				status: parseTransactionStatus(
					RESPONSE_CODES[
						String(transaction.response_code) as keyof typeof RESPONSE_CODES
					].status
				),
				status_code: `${
					RESPONSE_CODES[
						String(transaction.response_code) as keyof typeof RESPONSE_CODES
					].msg
				} - (${transaction.response_code})`,
				merchant_name: transaction.merchant_name || 'N/A',
				date: new Date(transaction.added_time).toLocaleString(),
				amount: convertCentsToCurrency(
					String(transaction.currency),
					transaction.txn_amount
				),
				card: transaction.pan_masked || 'N/A',
			};
			worksheet.addRow(row);
		});

		const currentTime = new Date();
		worksheet.addRow([
			`Gerado em ${currentTime.toLocaleDateString()} às ${currentTime.toLocaleTimeString(
				'pt-BR',
				{
					timeZone: 'America/Sao_Paulo',
					hour: '2-digit',
					minute: '2-digit',
				}
			)} no Bounty RH. Usuário: ${user.email}. Dados de: ${
				collaborator.collaborator_name
			}`,
		]);

		const buffer = await workbook.xlsx.writeBuffer();
		const fileType =
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

		const blob = new Blob([buffer], { type: fileType });

		return blob;
	}

	return (
		<>
			<ReportButton onClick={handleGenerateXLSX}>
				{fetchOperationalReportsQuery.isLoading ? (
					<Loader color='#fff' size={30} />
				) : (
					'Gerar relatório Excel'
				)}
			</ReportButton>
		</>
	);
}
