import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div``;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
`;

interface OptionLinkButtonProps {
	$disabled?: boolean;
}
export const OptionLinkButton = styled(Link)<OptionLinkButtonProps>`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	cursor: 'pointer';
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;
