import { useState } from 'react';
import { Transaction } from '../../../../@types/CorporateExpenses/Transaction';
import { MdNotes } from 'react-icons/md';
import * as S from '../CardsExtract/styles';
import PageTitle from '../../../../components/PageTitle';
import Modal from '../../../../components/Modal';
import {
	checkDeclineAndStrikeText,
	getTransactionType,
	parseExtractTransactionStatus,
	parseStatusTransaction,
	parseTransactionType,
} from '../../../../utils/transactionUtils';
import {
	convertCentsToCurrency,
	convertCentsToReais,
} from '../../../../utils/CurrencyConvert';
import {
	benefits,
	entry_mode,
} from '../../Collaborators/Transactions/components/TransactionsDetailsModal';
import { checkFinancialImpact } from '../../../../utils/parseFinancialImpact';
import cc from 'currency-codes';
import { MCCDetailsModal } from '../../Collaborators/Transactions/components/MCCDetailsModal';
import { toast } from 'react-toastify';

interface Props {
	activeViewer: boolean;
	transaction: Transaction;
	cardPan: string;
}

export function CardTransactionModal({
	activeViewer,
	transaction,
	cardPan,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);

	function checkRefund() {
		let refundsLinked: string[] = [];
		let refundedBy: string[] = [];

		transaction.linked_transactions.forEach((transaction) => {
			if (
				transaction.link_type === 'REVERSES' ||
				transaction.link_type === 'CLEARING_CREDITS' ||
				transaction.link_type === 'REFUNDS'
			) {
				refundsLinked.push(transaction.internal_transaction_id);
			} else if (
				transaction.link_type === 'REVERSED_BY' ||
				transaction.link_type === 'CLEARING_CREDITED_BY' ||
				transaction.link_type === 'REFUNDED_BY'
			) {
				refundedBy.push(transaction.internal_transaction_id);
			}
		});

		if (refundsLinked.length > 0) {
			return `Estorna a transação = ${refundsLinked.join(', ')}`;
		} else if (refundedBy.length > 0) {
			return `Estornada pela transação = ${refundedBy.join(', ')}`;
		}

		return 'Transação não é um estorno automático da financeira';
	}

	function getAmountField() {
		if (transaction.currency !== 986) {
			return (
				<S.InfoContainer>
					<S.InfoTitle>VALOR ORIGINAL</S.InfoTitle>
					{convertCentsToCurrency(
						String(transaction.currency),
						transaction.txn_amount
					)}

					<S.InfoTitle>VALOR EM REAIS</S.InfoTitle>
					{convertCentsToReais(transaction.billing_amount)}

					{transaction.clearing_state === 'PARTIALLY_CLEARED' && (
						<>
							<S.InfoTitle>VALOR DEBITADO</S.InfoTitle>
							{convertCentsToReais(transaction.amount_cleared_net)}
						</>
					)}
				</S.InfoContainer>
			);
		}

		return (
			<S.InfoContainer>
				<S.InfoTitle>VALOR</S.InfoTitle>
				{convertCentsToReais(transaction.billing_amount)}

				{transaction.clearing_state === 'PARTIALLY_CLEARED' && (
					<>
						<S.InfoTitle>VALOR DEBITADO</S.InfoTitle>
						{convertCentsToReais(transaction.amount_cleared_net)}
					</>
				)}
			</S.InfoContainer>
		);
	}

	if (!isOpen) {
		return (
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-testid='openButton_test_id'
				data-rh='Detalhamento'
			>
				<MdNotes />
			</S.openModalBttn>
		);
	}

	const handleCopyClick = () => {
		const idString = `FINANCIAL_IMPACT_TYPE = ${transaction.financial_impact_type}\nMSG_TYPE = ${transaction.msg_type}\nTXN_TYPE = ${transaction.txn_type}\nRESPONSE_CODE = ${transaction.response_code}\n`;
		navigator.clipboard.writeText(idString);

		toast.info('Dados copiados para a área de transferência.');
	};

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-testid='openButton_test_id'
				data-rh='Detalhamento'
			>
				<MdNotes />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container
					style={{ width: '60vw', height: '80vh', overflow: 'hidden' }}
				>
					<S.TitleWrapper>
						<PageTitle title='Detalhamento da transação' />
						<S.CopyIdBtn onClick={handleCopyClick}>Copiar campos</S.CopyIdBtn>
					</S.TitleWrapper>

					<S.GridContainer
						style={{ overflow: 'auto', height: '73vh', width: 'auto' }}
					>
						<S.InfoContainer>
							<S.InfoTitle>ID DA TRANSAÇÃO</S.InfoTitle>
							{transaction.internal_transaction_id!}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>PAN</S.InfoTitle>
							{cardPan}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>TRANSAÇÃO</S.InfoTitle>
							{parseStatusTransaction(transaction.response_code)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>STATUS</S.InfoTitle>
							{parseExtractTransactionStatus(
								transaction.msg_type,
								transaction.txn_type,
								transaction.response_code,
								transaction.cleared,
								transaction.clearing_state,
								transaction.financial_impact_type,
								activeViewer
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>DATA</S.InfoTitle>
							{new Date(transaction.added_time).toLocaleString()}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>LANÇAMENTO</S.InfoTitle>
							{parseTransactionType(
								transaction.txn_type,
								transaction.msg_type,
								transaction.response_code,
								transaction.currency,
								transaction.merchant_country
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>ESTABELECIMENTO</S.InfoTitle>
							{checkDeclineAndStrikeText(
								transaction.response_code,
								transaction.merchant_name
							)}
						</S.InfoContainer>

						{getAmountField()}

						<S.InfoContainer>
							<S.InfoTitle>Categoria</S.InfoTitle>
							{ getTransactionType(transaction.txn_type.toString(), transaction.msg_type.toString()) }
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Carteira</S.InfoTitle>
							{benefits[Number(transaction.balance_account_id) - 1] || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Valor da carteira antes da transação</S.InfoTitle>
							{convertCentsToReais(transaction.available_balance_before!)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>ID do Estabelecimento</S.InfoTitle>
							{transaction.merchant_id || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Estabelecimento</S.InfoTitle>
							{transaction.merchant_name || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Cidade do estabelecimento</S.InfoTitle>
							{transaction.merchant_city || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>País do estabelecimento</S.InfoTitle>
							{transaction.merchant_country || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>MOEDA</S.InfoTitle>

							{cc.number(String(transaction.currency).padStart(3, '0'))?.code ??
								'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>MCC</S.InfoTitle>
							{transaction.mcc ? (
								<MCCDetailsModal mccCode={transaction.mcc} />
							) : (
								'N/A'
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Forma de pagamento</S.InfoTitle>
							{entry_mode[
								String(transaction.pos_entry_mode!) as keyof typeof entry_mode
							] || 'N/A'}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Impacto financeiro</S.InfoTitle>
							{checkFinancialImpact(transaction.financial_impact_type)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Estorno</S.InfoTitle>
							{checkRefund()}
						</S.InfoContainer>
					</S.GridContainer>
				</S.Container>
			</Modal>
		</>
	);
}
