import styled from 'styled-components';

export const Container = styled.div`
	width: 60vw;
	height: 80vh;
`;
export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;
export const GridContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 1rem;
	text-transform: uppercase;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const OpenModalBtn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;
