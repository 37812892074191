import { CorpwayCostCenter, CostCenter } from '../../../../../@types/Company';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { ProductOriginLabel } from '../../../../../components/ProductOriginalLabel';
import { SortColumnButton } from '../../../../../components/SortColumnButton';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { useSortColumnHook } from '../../../../../hooks/useSortColumnHook/index';

interface Props {
	AllCostCenters: {
		costCenters: CostCenter[];
		corpwaycostCenters: CorpwayCostCenter[];
	};
}

export function CompanyCostCenters({ AllCostCenters }: Props) {
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	if (
		!AllCostCenters.costCenters?.length &&
		!AllCostCenters.corpwaycostCenters?.length
	) {
		return <EmptyContent big />;
	}

	const allCostCenters = [
		...AllCostCenters.costCenters.map((costCenter) => ({
			...costCenter,
			type: 'CostCenter',
		})),
		...AllCostCenters.corpwaycostCenters,
	];

	return (
		<TableStyle.Table>
			<TableStyle.TableHeader>
				<TableStyle.TableRow>
					<TableStyle.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'title'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('title')}
						/>
						CENTRO DE CUSTO
					</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'origin'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('origin')}
						/>
						PRODUTO
					</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'total_users'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('total_users')}
						/>
						COLABORADORES ASSOCIADOS
					</TableStyle.TableHeaderCell>
				</TableStyle.TableRow>
			</TableStyle.TableHeader>

			<TableStyle.TableBody>
				{sortList(allCostCenters).map((costCenter) => (
					<TableStyle.TableRow key={costCenter.id}>
						<TableStyle.TableData>{costCenter.title}</TableStyle.TableData>

						<TableStyle.TableData>
							<ProductOriginLabel
								origin={
									(costCenter as CostCenter).type ? 'multiflex' : 'corpway'
								}
							/>
						</TableStyle.TableData>
						<TableStyle.TableData>
							{(costCenter as CostCenter).total_users || '-'}
						</TableStyle.TableData>
					</TableStyle.TableRow>
				))}
			</TableStyle.TableBody>
		</TableStyle.Table>
	);
}
