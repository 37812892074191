import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { ReleasesTypeOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'Empresa',
		filter: 'company',
		value: '',
		type: 'company',
		selected: false,
	},
	{
		name: 'Grupo corporativo',
		filter: 'group_company',
		value: '',
		type: 'group_company',
		selected: false,
	},
	{
		name: 'Data Criação',
		filter: 'date_interval',
		value: '',
		type: 'date_interval',
		selected: false,
	},
	{
		name: 'Data Execução',
		filter: 'scheduled_interval',
		value: '',
		type: 'scheduled_interval',
		selected: false,
	},
	{
		name: 'Status',
		filter: 'status',
		value: '',
		type: 'select',
		selectOptions: ReleasesTypeOptionsFilters,
		selected: false,
	},
];

const useReleasesListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useReleasesListStore };
