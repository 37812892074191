import styled from 'styled-components';

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const HeaderTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.6rem;
	> svg {
		width: 2rem;
		height: 2rem;
		margin-left: 6rem;
		margin-right: 1rem;
	}
`;

export const alignStatus = styled.div`
	display: flex;
	alignitems: center;
`;

export const HeaderInfo = styled.div`
	display: flex;
	margin-top: 3rem;
	margin-bottom: 1.5rem;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--light-gray);
	margin-bottom: 1rem;
	text-transform: uppercase;
`;

export const InfoContainer = styled.div`
	font-size: 1.6rem;
	& + & {
		margin-left: 5rem;
	}

	span {
		font-weight: 500;
		font-size: 1.8rem;
		color: var(--light-gray);
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	p {
		font-size: 1.6rem;
		color: black;
		font-weight: normal;
		text-transform: none;
	}
`;
