export function checkCorpwayCardStatus(status: string) {
	switch (status) {
		case 'active':
			return 'ATIVO';
		case 'blocked':
			return 'BLOQUEADO';
		case 'canceled':
			return 'CANCELADO';
		default:
			return '???';
	}
}
