import { ComponentType } from "react";
import { IconBaseProps } from "react-icons";

import * as S from "./styles";

type Props = {
  icon: ComponentType<IconBaseProps>;
  title: string;
  path: string;
  currentPage?: boolean;
};

export function SidebarLink({ icon: Icon, title, path, currentPage = false }: Props) {
  return (
    <S.Container to={path} $currentPage={currentPage}>
      {Icon && <Icon />}
      <S.Title>{title}</S.Title>
    </S.Container>
  );
}
