import { Partner } from '../../../../../@types/Partner';
import { EmptyContent } from '../../../../../components/EmptyContent';
import * as TableStyle from '../../../../../components/Table/TableStyles';

interface Props {
	partners: Partner[];
}

export function CompanyEnabledPartners({ partners }: Props) {
	if (!partners?.length) {
		return <EmptyContent big />;
	}

	return (
		<TableStyle.Table>
			<TableStyle.TableHeader>
				<TableStyle.TableRow>
					<TableStyle.TableHeaderCell>PARCEIRO</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>DESCRIÇÃO</TableStyle.TableHeaderCell>
					<TableStyle.TableHeaderCell>CATEGORIA</TableStyle.TableHeaderCell>
				</TableStyle.TableRow>
			</TableStyle.TableHeader>

			<TableStyle.TableBody>
				{partners
					.filter((partner) => partner.enabled)
					.map((partner) => (
						<TableStyle.TableRow key={partner.id}>
							<TableStyle.TableData>
								{partner.partner.partner_name}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{partner.partner.partner_description}
							</TableStyle.TableData>
							<TableStyle.TableData>
								{partner.partner.partner_category}
							</TableStyle.TableData>
						</TableStyle.TableRow>
					))}
			</TableStyle.TableBody>
		</TableStyle.Table>
	);
}
