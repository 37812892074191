import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import { KYCStatusOptionsFilters, StatusOptionsFilters } from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

export const filterParams: FilterParams[] = [
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
	{
		name: 'Email',
		filter: 'email',
		value: '',
		type: 'email',
		selected: false,
	},
	{
		name: 'Empresa',
		filter: 'company',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Status KYC',
			filter: 'kyc_status',
		value: '',
		type: 'select',
		selectOptions: KYCStatusOptionsFilters,
		selected: false,
	},
	{
		name: 'Status',
			filter: 'status',
		value: '',
		type: 'select',
		selectOptions: StatusOptionsFilters,
		selected: false,
	},
	{
		name: '2º Via dos cartões',
			filter: 'duplicate_card',
		value: '',
		type: 'toggle',
		selected: false,
	},
	{
		name: 'ID usuário swap',
			filter: 'id_baas',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'ID conta swap',
			filter: 'id_wallet_baas_cclg',
		value: '',
		type: 'text',
		selected: false,
	},
];

const useCollaboratorsListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useCollaboratorsListStore };
