export function phoneMask(value: string) {
  // regex for testing the pattern
  // const re = /\+([0-9]+( [0-9]+)+)-([0-9])/i;
  value = value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "+$1 $2")
    .replace(/(\d{2})(\d)/, "$1 $2");

  if (value.length < 16) {
    return value.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return value.replace(/(\d{5})(\d)/, "$1-$2").substring(0, 17);
}
