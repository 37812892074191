import { ReleaseDetails } from '../../../../../@types/Release';
import { parseDate } from '../../../../../utils/parseDate';
import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';

import {
	parsePaymentStatus,
	getPaymentStatusIcon,
} from '../../../../../utils/status/parsePaymentStatus';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { cnpjMask } from '../../../../../utils/masks/cnpj';
import CompanyCard from '../../../../../components/CompanyCard';

interface Props {
	release: ReleaseDetails;
	title: string;
}

export function PaymentHeader(props: Props) {
	return (
		<S.Header>
			<S.HeaderTitle>
				<PageTitle title={props.title} />
			</S.HeaderTitle>

			<S.HeaderInfo>
				<S.InfoContainer>
					<S.InfoTitle>GRUPO CORPORATIVO</S.InfoTitle>
					{props.release.release.corporate_group.name}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>EMPRESA</S.InfoTitle>
					<CompanyCard
						name={props.release.release.company.name}
						bottomInfo={cnpjMask(props.release.release.company.cnpj)}
					/>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>OPERADOR</S.InfoTitle>
					{props.release.release.userOperatorCreator.name}
				</S.InfoContainer>
			</S.HeaderInfo>

			<S.HeaderInfo>
				<S.InfoContainer>
					<S.InfoTitle>ID</S.InfoTitle>
					{props.release.release.order_id}
				</S.InfoContainer>

				<S.InfoContainer>
					{parseDate(
						props.release.release.createdAt,
						1,
						Number(props.release.release.referenceMonth)
					)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>STATUS</S.InfoTitle>
					<S.alignStatus>
						{getPaymentStatusIcon(props.release.release.status)}
						<span style={{ margin: '0 4px' }}></span>
						{parsePaymentStatus(props.release.release.status)}
					</S.alignStatus>
				</S.InfoContainer>
				{props.release.release.preReleaseCount !== undefined &&
					props.release.release.preReleaseCount !== null && (
						<S.InfoContainer>
							<S.InfoTitle>qtde Pagamentos</S.InfoTitle>
							{props.release.release.preReleaseCountWithStatus} /
							{props.release.release.preReleaseCount}
						</S.InfoContainer>
					)}
				<S.InfoContainer>
					<S.InfoTitle>Valor Total</S.InfoTitle>
					{convertCentsToReais(Number(props.release.release.totalValue!))}
				</S.InfoContainer>
				{props.release.release.collaboratorCount !== undefined &&
					props.release.release.preReleaseCount !== null && (
						<S.InfoContainer>
							<S.InfoTitle>qtde Colaboradores</S.InfoTitle>
							{props.release.release.collaboratorCount}
						</S.InfoContainer>
					)}
				{props.release.release.userOperatorCanceling && (
					<S.InfoContainer>
						<S.InfoTitle>Cancelado por</S.InfoTitle>
						{props.release.release.userOperatorCanceling.name}
					</S.InfoContainer>
				)}
				{props.release.release.reasonDisapproval && (
					<S.InfoContainer>
						<S.InfoTitle>Motivo do cancelamento</S.InfoTitle>
						{props.release.release.reasonDisapproval}
					</S.InfoContainer>
				)}
				{props.release.release.total_chargeback && (
					<S.InfoContainer>
						<S.InfoTitle>Valor total estornado</S.InfoTitle>
						{convertCentsToReais(
							Number(props.release.release.total_chargeback)
						)}
					</S.InfoContainer>
				)}
			</S.HeaderInfo>
		</S.Header>
	);
}
