import { createContext, ReactNode, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from '../hooks/useAuth';
import {
	getNotifications,
	toggleNotificationStatus,
} from '../services/queries/Notifications';
import { showErrorMessage } from '../utils/ErrorHandler';
import { getNotificationToken, messaging } from '../services/firebase';
import { onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';

export interface Notification {
	id: string;
	company_id: string;
	operator_id: string;
	master_id: string | null;
	collaborator_id: string | null;
	user_collaborator: {
		first_name: string;
		last_name: string;
	} | null;
	title?: string;
	message: string;
	was_read: boolean;
	event_type: string;
	resource_id: string | null;
	created_at: Date;
	// updated_at: 2022-05-17T11:40:11.452Z
}

export interface NotificationsFetchData {
	totalNotifications: number;
	notificationsOperator: Notification[];
}

export interface NotificationData {
	unreadNotifications: number;
	toggleNotificationsStatus: (ids: string[], status: boolean) => Promise<void>;
}

interface ToggleNotificationStatusParams {
	notificationId: string;
	status: boolean;
}

export const NotificationContext = createContext({} as NotificationData);

type NotificationProps = {
	children: ReactNode;
};
export function NotificationProvider({ children }: NotificationProps) {
	const { user } = useAuth();
	const queryClient = useQueryClient();
	const notificationSound = new Audio('/notification_sound.wav');

	useEffect(() => {
		if (user && messaging) {
			getNotificationToken(messaging);

			// Firebase Message observer event
			onMessage(messaging, (payload) => {
				notificationSound.play();

				toast.info(
					<NotificationToaster
						body={payload.data ? payload.data.message : ''}
					/>
				);
				fetchNotificationsQuery.refetch();
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const onSWBackgroundNotificationEvent = (e: MessageEvent) => {
			if (e.data.type === 'background-notification-event') {
				fetchNotificationsQuery.refetch(); // to update the amount of unseen notifications (that shows on top)
				notificationSound.play();
			}
		};

		navigator.serviceWorker.addEventListener(
			'message',
			onSWBackgroundNotificationEvent
		);
		return () => {
			navigator.serviceWorker.removeEventListener(
				'message',
				onSWBackgroundNotificationEvent
			);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchNotificationsQuery = useQuery(
		['fetchNotifications', 1],
		() => {
			return getNotifications(1);
		},
		{
			onSuccess: (data) => {
				// UPDATE page title with the amount of unreadNotifications
				if (data.totalNotificationsUnread > 0)
					document.title = `(${data.totalNotificationsUnread}) Bounty | cs`;
				else document.title = `Bounty | cs`;
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as notificações.'
				);
			},
			enabled: !!user,
			refetchOnWindowFocus: true,
		}
	);

	const toggleNotificationsStatusQuery = useMutation(
		({ notificationId, status }: ToggleNotificationStatusParams) => {
			return toggleNotificationStatus(notificationId, status);
		}
	);

	async function toggleNotificationsStatus(ids: string[], status: boolean) {
		await Promise.all(
			ids.map((notificationId) =>
				toggleNotificationsStatusQuery.mutateAsync({ notificationId, status })
			)
		).then(() => {
			queryClient.invalidateQueries('fetchNotifications');
		});
	}

	function NotificationToaster({ body }: { body: string }) {
		return (
			<div>
				<h4>Você recebeu uma nova notificação</h4>
				<p>{body}</p>
			</div>
		);
	}

	return (
		<NotificationContext.Provider
			value={{
				unreadNotifications:
					fetchNotificationsQuery.data?.totalNotificationsUnread ?? 0,
				toggleNotificationsStatus,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
}
