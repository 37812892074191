import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${(props) => props.color || "var(--black)"};
  font-weight: 600;
  font-size: 2.2rem;
`;

export const TotalRecords = styled.span`
  font-size: 1.4rem;
`;
