import * as S from './styles';
import { TextWithHint } from '../TextWithHint';

interface UserCardProps {
	name: string;
	bottomInfo?: string;
	nameWidth?: string;
	companyName?: string;
	id?: string;
	fontSize?: string;
	fontColor?: string;
}

export default function UserCard({
	name,
	bottomInfo = '',
	fontSize = '',
	fontColor = '',
	nameWidth = '21rem',
	companyName = '',
}: UserCardProps) {
	return (
		<S.Container>
			<S.InfoContainer>
				<TextWithHint
					text={name}
					width={nameWidth}
					fontSize={fontSize}
					fontColor={fontColor}
				/>
				<S.BottomInfo>
					<TextWithHint
						text={bottomInfo}
						fontSize={fontSize}
						fontColor={fontColor}
					/>
				</S.BottomInfo>
				<S.BottomInfo>
					<TextWithHint text={companyName} />
				</S.BottomInfo>
			</S.InfoContainer>
		</S.Container>
	);
}
