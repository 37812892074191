import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	parseSortColumnToParams,
	SortColumn,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface ReportResponse {
	totalReports: number;
	totalCount: number;
	collaborators: {
		id: string;
		first_name: string;
		last_name: string;
		cpf: string;
		transaction_date: string;
		scheduled_date: string;
		release_date: string;
		card_status: string;
	}[];
}

export async function getOperationalReport(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<ReportResponse>(
		`/api/v1/cs/operational-report/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getOperationalReportToPDF(
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<ReportResponse>(
		`/api/v1/cs/operational-report/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}
