import { useState } from 'react';
import { Collaborator } from '../../../../../@types/Collaborators';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';
import { MdNotes } from 'react-icons/md';
import { TabPanel, Tabs } from 'react-tabs';
import * as TabStyle from '../../../../../components/Tab/TabStyles';
import { GeneralData } from './CollaboratorDetailsTabs/GeneralData/GeneralData';
import { EmploymentData } from './CollaboratorDetailsTabs/EmploymentData';
import { AddressData } from './CollaboratorDetailsTabs/AddressData';

export interface Props {
	collaborator: Collaborator;
}

export function CollaboratorDetailsModal({ collaborator }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-rh='Detalhamento'
				data-testid='openButton_test_id'
			>
				<MdNotes />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						<PageTitle
							title={`Dados do colaborador: ${collaborator.first_name} ${collaborator.last_name}`}
						/>
					</S.TitleWrapper>
					<Tabs>
						<TabStyle.TabListContainer>
							<TabStyle.TabCustomized>Dados Gerais</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>Dados de Vínculo</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>Dados de Endereço</TabStyle.TabCustomized>
						</TabStyle.TabListContainer>

						<TabPanel>
							<GeneralData collaborator={collaborator} />
						</TabPanel>

						<TabPanel>
							<EmploymentData collaborator={collaborator} />
						</TabPanel>

						<TabPanel>
							<AddressData collaborator={collaborator} />
						</TabPanel>
					</Tabs>
				</S.Container>
			</Modal>
		</>
	);
}
