import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Filter, FilterParams } from '../../../../../../components/Filter';
import Loader from '../../../../../../components/Loader';
import { useSortColumnHook } from '../../../../../../hooks/useSortColumnHook';
import { getCompanyFundsIn } from '../../../../../../services/queries/Companies';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import * as TableStyles from '../../../../../../components/Table/TableStyles';
import * as S from './styles';
import { EmptyContent } from '../../../../../../components/EmptyContent';
import { Company, Transfer } from '../../../../../../@types/Company';
import { SortColumnButton } from '../../../../../../components/SortColumnButton';
import { convertCentsToReais } from '../../../../../../utils/CurrencyConvert';
import { parseDate } from '../../../../../../utils/parseDate';
import { Pagination } from '../../../../../../components/Pagination';
import { ReportGenerator } from './ReportGenerator';
import { ProductOriginLabel } from '../../../../../../components/ProductOriginalLabel';

interface Props {
	company: Company;
}

export function CompanyFundsIn({ company }: Props) {
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();
	const [filtersParams, setFiltersParams] = useState<FilterParams[]>([
		{
			name: 'Data',
			filter: 'date',
			value: '',
			type: 'date_interval',
			selected: false,
		},
	]);

	const fetchCompanyFundsIn = useQuery(
		[
			'companiesFundsIn',
			currentPage,
			filtersParams,
			currentSortColumn,
			company.id,
		],
		() => {
			return getCompanyFundsIn(
				currentPage,
				filtersParams,
				currentSortColumn,
				company.id
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o histórico de recarga da empresa.'
				);
			},
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('companiesFundsIn');
	}

	function getTable(transfers: Transfer[]) {
		if (!transfers.length) {
			return (
				<>
					<TableStyles.TableHeaderContainer>
						<TableStyles.TitleWrapper>
							<Filter
								filterParams={filtersParams}
								onFiltersChanged={(updatedFilters) => {
									setCurrentPage(1);
									setFiltersParams(updatedFilters);
								}}
							/>
						</TableStyles.TitleWrapper>
						<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
					</TableStyles.TableHeaderContainer>

					<EmptyContent big />
				</>
			);
		}

		return (
			<>
				<TableStyles.TableHeaderContainer>
					<TableStyles.TitleWrapper>
						<Filter
							filterParams={filtersParams}
							onFiltersChanged={(updatedFilters) => {
								setCurrentPage(1);
								setFiltersParams(updatedFilters);
							}}
						/>
						<S.TotalRecords data-testid='total-records'>
							Total: <b>{fetchCompanyFundsIn.data.totalTransfers}</b> registros
						</S.TotalRecords>
					</TableStyles.TitleWrapper>
					<Pagination
						onPageChange={(page) => setCurrentPage(page)}
						currentPage={currentPage}
						totalCount={fetchCompanyFundsIn.data?.totalTransfers!}
					/>
					<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyles.TableHeaderContainer>

				<TableStyles.Table>
					<TableStyles.TableHeader>
						<TableStyles.TableRow>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'created_at'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('created_at')}
								/>
								DATA
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'amount'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('amount')}
								/>
								VALOR
							</TableStyles.TableHeaderCell>
							<TableStyles.TableHeaderCell>
								<SortColumnButton
									order={
										currentSortColumn?.name === 'origin'
											? currentSortColumn.order
											: null
									}
									onToggleSort={() => toggleSort('origin')}
								/>
								PRODUTO
							</TableStyles.TableHeaderCell>
						</TableStyles.TableRow>
					</TableStyles.TableHeader>

					<TableStyles.TableBody>
						{transfers.map((transfer) => (
							<TableStyles.TableRow key={transfer.id}>
								<TableStyles.TableData style={{ width: '33%' }}>
									{parseDate(transfer.created_at, 1)}
								</TableStyles.TableData>
								<TableStyles.TableData style={{ width: '33%' }}>
									{convertCentsToReais(Number(transfer.amount))}
								</TableStyles.TableData>
								<TableStyles.TableData style={{ width: '33%' }}>
									<ProductOriginLabel origin={transfer.origin} />
								</TableStyles.TableData>
							</TableStyles.TableRow>
						))}
					</TableStyles.TableBody>
				</TableStyles.Table>
			</>
		);
	}

	if (fetchCompanyFundsIn.isLoading || !fetchCompanyFundsIn.data) {
		return (
			<S.Container>
				<Loader />
			</S.Container>
		);
	}

	return (
		<S.Container>
			{getTable(fetchCompanyFundsIn.data.transfers)}

			{fetchCompanyFundsIn.data.transfers &&
				fetchCompanyFundsIn.data.transfers.length > 0 && (
					<S.ButtonsContainer>
						<ReportGenerator
							filterParams={filtersParams}
							sortColumn={currentSortColumn}
							company={company}
						/>
					</S.ButtonsContainer>
				)}
		</S.Container>
	);
}
