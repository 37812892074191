import {
	Document,
	Page,
	Text,
	View,
	Font,
	pdf,
	Image,
} from '@react-pdf/renderer';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Company } from '../../../../../../../@types/Company';
import { FilterParams } from '../../../../../../../components/Filter';
import Loader from '../../../../../../../components/Loader';
import {
	PDFstyles,
	ReportButton,
} from '../../../../../../../components/PDFReportStyles';
import { SortColumn } from '../../../../../../../hooks/useSortColumnHook';
import { getCompanyFundsInReport } from '../../../../../../../services/queries/Companies';
import { convertCentsToReais } from '../../../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../../../utils/ErrorHandler';
import BountyLogo from '../../../../../../../assets/FlexibleBenefitsLogo.png';
import ReportIcon from '../../../../../../../assets/ReportIcon.png';
import { cnpjMask } from '../../../../../../../utils/masks/cnpj';

Font.register({
	family: 'Ubuntu',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf',
		},
	],
});

interface ReportProps {
	company: Company;
	filterParams: FilterParams[];
	sortColumn: SortColumn | null;
}

export function ReportGenerator({
	company,
	filterParams,
	sortColumn,
}: ReportProps) {
	const fetchFundsInQuery = useQuery(
		['companiesFundsInToReport', filterParams, sortColumn, company.id],
		() => {
			return getCompanyFundsInReport(filterParams, sortColumn, company.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o histórico de recarga da empresa.'
				);
			},
			enabled: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	async function handleGeneratePDF() {
		try {
			const PDFGenerated = await generatePDFContent();
			const blob = await pdf(PDFGenerated).toBlob();

			// Create a temporary URL for the blob
			const url = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement('a');
			link.href = url;
			link.download = `relatorio_de_recargas_${company.name}.pdf`;

			// Simulate a click event to trigger the download
			link.dispatchEvent(new MouseEvent('click'));

			// Clean up the temporary URL
			URL.revokeObjectURL(url);
		} catch (error) {
			toast.error(
				'Ocorreu um erro ao tentar gerar o arquivo de relátorio. Tente novamente'
			);
		}
	}

	async function generatePDFContent() {
		const reportFetching = await fetchFundsInQuery.refetch();
		const { transfers, totalTransfers, totalValue, totalReports } =
			reportFetching.data!;

		let filters: { name: string; value: string; second_value?: string }[] = [];

		filterParams.forEach((filter) => {
			if (filter.selected) {
				filters.push({
					name: filter.name,
					value: filter.value,
					second_value: filter.second_value,
				});
			}
		});

		return (
			<Document>
				<Page size='A4' style={PDFstyles.page} orientation='landscape'>
					<View style={PDFstyles.header} fixed>
						<Image src={BountyLogo} style={PDFstyles.logo} />
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>Nome:</Text>
							<Text style={PDFstyles.headerInfoData}>{company.name}</Text>
						</View>

						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>CNPJ:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{cnpjMask(company.cnpj)}
							</Text>
						</View>

						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>N° Página:</Text>
							<Text
								style={PDFstyles.headerInfoData}
								render={({ pageNumber, totalPages }) =>
									`${String(pageNumber).padStart(2, '0')}/${String(
										totalPages
									).padStart(2, '0')}`
								}
							></Text>
						</View>
						<View style={PDFstyles.headerInfo}>
							<Text style={PDFstyles.headerInfoLabel}>Data do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{Intl.DateTimeFormat('pt-BR', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									hour12: false,
								}).format(new Date())}
							</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '120%' }}>
							<Text style={PDFstyles.headerInfoLabel}>Intervalo de busca:</Text>
							<Text style={PDFstyles.headerInfoData}>Todo o período</Text>
						</View>

						<View style={{ ...PDFstyles.headerInfo, width: '60%' }}>
							<Text style={PDFstyles.headerInfoLabel}>N° do pedido:</Text>
							<Text style={PDFstyles.headerInfoData}>
								{String(totalReports).padStart(7, '0')}
							</Text>
						</View>
					</View>

					<View style={PDFstyles.subHeader}>
						<View style={PDFstyles.titleContainer}>
							<Image src={ReportIcon} style={PDFstyles.reportLogo} />
							<View style={PDFstyles.reportTitleContainer}>
								<Text
									style={{
										...PDFstyles.reportTitle,
										color: '#FF0037',
									}}
								>
									Relatório de
								</Text>

								<Text> </Text>

								<Text style={PDFstyles.reportTitle}>recargas</Text>
							</View>
						</View>

						<View style={PDFstyles.subHeaderDataContainer}>
							<View style={{ ...PDFstyles.subHeaderInfo }}>
								<Text style={PDFstyles.subHeaderLabel}>Valor total:</Text>
								<Text style={PDFstyles.subHeaderData}>
									{convertCentsToReais(Number(totalValue))}
								</Text>
							</View>

							<View style={{ ...PDFstyles.subHeaderInfo }}>
								<Text style={PDFstyles.subHeaderLabel}>
									Total de registros:
								</Text>
								<Text style={PDFstyles.subHeaderData}>{totalTransfers}</Text>
							</View>
						</View>
					</View>

					<View
						style={{
							...PDFstyles.tableContainer,
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<View style={PDFstyles.tableHeader}>
							<View
								style={{
									...PDFstyles.columnTitleContainer,
									width: '20%',
								}}
							>
								<Text style={PDFstyles.columnTitle}>Nº</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Data</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Valor</Text>
							</View>

							<View style={PDFstyles.columnTitleContainer}>
								<Text style={PDFstyles.columnTitle}>Produto</Text>
							</View>
						</View>

						{transfers.map((t: any, i: any) => (
							<View style={PDFstyles.tableRow} key={i} wrap={false}>
								<Text
									style={{
										...PDFstyles.columnData,
										width: '20%',
									}}
								>
									{String(i + 1)}
								</Text>
								<Text style={PDFstyles.columnData}>
									{new Date(t.created_at).toLocaleDateString()}
								</Text>
								<Text style={PDFstyles.columnData}>
									{convertCentsToReais(Number(t.amount))}
								</Text>
								<Text style={PDFstyles.columnData}>
									{t.origin === 'multiflex'
										? 'Benefícios Flexíveis '
										: 'Bounty Control'}
								</Text>
							</View>
						))}
					</View>
				</Page>
			</Document>
		);
	}

	return (
		<>
			<ReportButton onClick={handleGeneratePDF}>
				{fetchFundsInQuery.isLoading ? (
					<Loader color='#fff' size={15} />
				) : (
					'Gerar relatório'
				)}
			</ReportButton>
		</>
	);
}
