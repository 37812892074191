import { Benefit } from '../../@types/Benefit';
import { getBenefitParsedTitle } from './getBenefitParsedTitle';

export function sortBenefitsByAlfabeticalOrder(benefits: Benefit[]) {
	return benefits.sort((a: Benefit, b: Benefit) => {
		if (getBenefitParsedTitle(a.title) < getBenefitParsedTitle(b.title)) {
			return -1;
		}
		if (getBenefitParsedTitle(a.title) > getBenefitParsedTitle(b.title)) {
			return 1;
		}
		return 0;
	});
}
