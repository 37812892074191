import { Collaborator } from '../../@types/Collaborators';
import { Company } from '../../@types/Company';
import { Transaction } from '../../@types/Transactions';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	parseSortColumnToParams,
	SortColumn,
} from '../../hooks/useSortColumnHook';
import api from '../api';

export interface CustomTransaction extends Transaction {
	collaborator: Collaborator;
	company: Company;
}
interface TransactionsResponse {
	transactions: CustomTransaction[];
	totalTransactions: number;
}
export async function getAllTransactions(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<TransactionsResponse>(
		`/api/v1/cs/transactions/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getTransaction(
	collaboratorId: string,
	transactionId: string
) {
	const { data } = await api.get(
		`/api/v1/cs/transactions/${collaboratorId}/${transactionId}`
	);

	return data;
}

export async function getTransactionV2(transactionId: string) {
	const { data } = await api.get(`/api/v1/cs/transactions_v2/${transactionId}`);

	return data;
}

export async function getTransactionV3(transactionId: string) {
	const { data } = await api.get(`/api/v1/cs/transactions_v3/${transactionId}`);

	return data;
}


export interface MCCBenefits {
	transporte: boolean;
	alimentação: boolean;
	refeição: boolean;
	saúde: boolean;
	home_office: boolean;
	cultura: boolean;
	reembolso: boolean;
	causas: boolean;
	premio: boolean;
	games: boolean;
	viagens: boolean;
	educação: boolean;
	jus: boolean;
	maternidade: boolean;
}
interface MCCDetailsResponse extends MCCBenefits {
	id: string;
	code: string;
	description: string;
	id_group: string;
	group_description: string;
	description_extract: string;
}
export async function getMCCDetails(mccCode: string) {
	const { data } = await api.get<MCCDetailsResponse>(
		`/api/v1/cs/mcc/${mccCode}`
	);
	return data;
}
