import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const transactionTypesOptionsFilters = [
	{
		text: 'Adição de saldo',
		value: 'balance_adition',
	},
	{
		text: 'Compra cancelada',
		value: 'canceled_transaction',
	},
	{
		text: 'Compra nacional',
		value: 'national_purchase',
	},
	{
		text: 'Compra internacional',
		value: 'international_purchase',
	},
	{
		text: 'Estorno externo',
		value: 'external_refund',
	},
	{
		text: 'Estorno interno',
		value: 'internal_refund',
	},
	{
		text: 'IOF',
		value: 'fee',
	},
];
const filterParams: FilterParams[] = [
	{
		name: 'Lançamento',
		filter: 'transaction_type',
		value: '',
		type: 'select',
		selectOptions: transactionTypesOptionsFilters,
		selected: false,
	},
];

const useCardExtractListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useCardExtractListStore };
