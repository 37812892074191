import { BsArrowDownShort, BsClockFill } from 'react-icons/bs';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Notification } from '../../contexts/NotificationsContext';
import { useAuth } from '../../hooks/useAuth';
import { useNotifications } from '../../hooks/useNotifications';
import {
	getNotifications,
	readAllNotifications,
} from '../../services/queries/Notifications';
import { EmptyContent } from '../EmptyContent';
import Loader from '../Loader';
import * as S from './styles';
import { showErrorMessage } from '../../utils/ErrorHandler';

interface NotificationListProps {
	open: boolean;
}
export function NotificationsList({ open }: NotificationListProps) {
	const queryClient = useQueryClient();
	// const { companies, changeCurrentCompany, user } = useAuth();
	const { toggleNotificationsStatus, unreadNotifications } = useNotifications();

	const fetchNotificationsQuery = useInfiniteQuery(
		['fetchNotifications'],
		({ pageParam = 1 }) => getNotifications(pageParam),
		{
			getNextPageParam: (lastPage, pages) => {
				const currentPage = pages.length;
				const pagesLimit =
					lastPage.totalNotifications === 0
						? 1
						: Math.ceil(lastPage.totalNotifications / 10);
				// there are still pages? return 'currentPage + 1', there aren't any left? return 'undefined'
				return currentPage < pagesLimit ? currentPage + 1 : undefined;
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Ocorreu um erro ');
				//toast.error('Ocorreu um problema ao buscar as notificações.');
			},

			enabled: open,
		}
	);

	const readAllNotificationsQuery = useMutation(readAllNotifications, {
		onSuccess: async () => {
			await queryClient.invalidateQueries('fetchNotifications');
		},
	});

	function getNotificationsList() {
		return fetchNotificationsQuery.data?.pages.flatMap(
			(page) => page.notificationsCs
		);
	}

	function parseEventTypeToPTBR(eventType: string, title?: string) {
		const categories = {
			delete_collab_request: 'Solicitação de exclusão',
		};

		return categories[eventType as keyof typeof categories] ?? eventType;
	}

	function parseDateTimeToBrazil(createdAt: Date) {
		const brazilTimezoneDate = new Date(createdAt);

		return brazilTimezoneDate
			.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			})
			.replace(' ', ' - ');
	}

	function getDetailsLink(notification: Notification) {
		switch (notification.event_type) {
			case 'delete_collab_request':
				return `/requests_pending`;

			default:
				return '#';
		}
	}

	function renderLoadMoreBtn() {
		if (fetchNotificationsQuery.hasNextPage)
			return (
				<S.FooterBtn
					onClick={() => {
						fetchNotificationsQuery.fetchNextPage();
					}}
					style={
						fetchNotificationsQuery.isFetchingNextPage
							? { display: 'none' }
							: {}
					}
					disabled={fetchNotificationsQuery.isFetchingNextPage}
				>
					Carregar mais
				</S.FooterBtn>
			);
		return <div />;
	}

	function handleSeeNotificationDetails(notification: Notification) {
		// if (notification.company_id) changeCurrentCompany(notification.company_id);

		if (!notification.was_read)
			toggleNotificationSeenStatus([notification.id], true);
	}

	function toggleNotificationSeenStatus(ids: string[], status: boolean) {
		toggleNotificationsStatus(ids, status);
	}

	function handleReadAllNotifications() {
		readAllNotificationsQuery.mutate();
	}

	return (
		<S.NotificationsList>
			{getNotificationsList()?.map((notification) => (
				<S.Notification key={notification.id} $unread={!notification.was_read}>
					<S.NotificationTop>
						<S.NotificationDate>
							{parseDateTimeToBrazil(new Date(notification.created_at))}
						</S.NotificationDate>

						<S.NotificationOptionBtn
							onClick={() =>
								toggleNotificationSeenStatus(
									[notification.id],
									!notification.was_read
								)
							}
							$unread={!notification.was_read}
							data-testid={`toggle_read_${notification.id}`}
						>
							<BsClockFill
								data-rh={`Marcar como ${
									notification.was_read ? 'não' : ''
								} lida`}
							/>
						</S.NotificationOptionBtn>
					</S.NotificationTop>

					<S.NotificationType>
						{parseEventTypeToPTBR(notification.event_type, notification.title)}
					</S.NotificationType>

					<S.NotificationMessage $unread={!notification.was_read}>
						{notification.message}
					</S.NotificationMessage>

					<S.SeeDetailsLink
						to={getDetailsLink(notification)}
						$unread={!notification.was_read}
						onClick={() => handleSeeNotificationDetails(notification)}
					>
						{getDetailsLink(notification) !== '#' && (
							<span>
								Ver mais <BsArrowDownShort />
							</span>
						)}
					</S.SeeDetailsLink>

					<S.Divider $unread={!notification.was_read} />
				</S.Notification>
			))}

			{(fetchNotificationsQuery.isLoading ||
				fetchNotificationsQuery.isFetchingNextPage) && (
				<S.LoadingContainer>
					<Loader size={30} />
				</S.LoadingContainer>
			)}
			{fetchNotificationsQuery.data?.pages[0].totalNotifications === 0 && (
				<EmptyContent text='Nenhuma notificação' />
			)}

			<S.Footer>
				{renderLoadMoreBtn()}
				{unreadNotifications > 0 && (
					<S.FooterBtn onClick={handleReadAllNotifications}>
						Marcar todas como lidas
					</S.FooterBtn>
				)}
			</S.Footer>
		</S.NotificationsList>
	);
}
