import { Notification } from '../../contexts/NotificationsContext';
import api from '../api';

interface NotificationsListReturn {
	notificationsCs: Notification[];
	totalNotifications: number;
	totalNotificationsUnread: number;
}

export async function getNotifications(
	pageNumber?: number
): Promise<NotificationsListReturn> {
	const { data } = await api.get(
		`/api/v1/cs/notifications/q?page=${pageNumber}`
	);
	const { notificationsCs, totalNotifications, totalNotificationsUnread } =
		data;
	return {
		notificationsCs,
		totalNotifications,
		totalNotificationsUnread,
	};
}

export async function toggleNotificationStatus(
	notificationId: string,
	status: boolean
): Promise<void> {
	return await api.patch(`/api/v1/cs/notifications/${notificationId}/status`, {
		status,
	});
}

export async function readAllNotifications(): Promise<void> {
	return await api.put(`/api/v1/cs/notifications/all/status`, { status: true });
}
