import { create } from 'zustand';
import { FilterParams } from '../components/Filter';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'Empresa',
		filter: 'company_id',
		value: '',
		type: 'company_id',
		selected: false,
	},
	{
		name: 'Cartões',
		filter: 'pan',
		value: '',
		type: 'pan',
		selected: false,
	},
];

const useCorpwayCardsListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useCorpwayCardsListStore };
