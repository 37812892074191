import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;
`;

export const Avatar = styled.img`
	min-width: 4rem;
	max-width: 4rem;
	min-height: 4rem;
	max-height: 4rem;
	border-radius: 50%;
	border: 0.2rem solid var(--terciary);
	margin-right: 1rem;
	object-fit: cover;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 50px;
`;

export const Name = styled.div`
	font-size: 1.6rem;
`;

export const NameLink = styled(Link)`
	font-size: 1.6rem;
	color: var(--primary-blue);
`;

export const BottomInfo = styled.div`
	font-size: 1.4rem;
	color: var(--mid-gray);
`;
