import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;

	span {
		text-transform: none;
		font-size: 1.6rem;
		font-weight: normal;
		color: var(--black);
	}

	p {
		font-size: 1.3rem;
		color: black;
		font-weight: normal;
		text-transform: none;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
