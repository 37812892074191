import { Collaborator } from '../../@types/Collaborators';
import { Transaction } from '../../@types/Transactions';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface CollaboratorsResponse {
	totalCount: number;
	collaborators: Collaborator[];
}

export async function getCollaborators(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<CollaboratorsResponse>(
		`/api/v1/cs/collaborators/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCollaboratorsByCompany(
	companyId: string,
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<CollaboratorsResponse>(
		`/api/v1/cs/companies/${companyId}/collaborators/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

interface TransactionsResponse {
	collaborator: {
		collaborator_id: string;
		collaborator_name: string;
	};

	transactions: Transaction[];
}

interface TransactionsResponseV2 {
	collaborator: {
		collaborator_id: string;
		collaborator_name: string;
		collaborator_cpf: string;
	};

	transactions: Transaction[];
	totalTransactions: number;
	totalReports: number;
	totalValue: number;
}

export async function getCollaboratorTransactions(
	collaboratorId: string,
	start_period: string,
	end_period: string,
	limit: string,
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<TransactionsResponse>(
		`/api/v1/cs/transactions/${collaboratorId}`,
		{
			params: {
				start_period,
				end_period,
				limit,
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCollaboratorTransactionsV2(
	collaboratorId: string,
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<TransactionsResponseV2>(
		`/api/v1/cs/transactions_v2/${collaboratorId}/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCollaboratorTransactionsV3(
	collaboratorId: string,
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<TransactionsResponseV2>(
		`/api/v1/cs/transactions/v3/${collaboratorId}/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function getCollaboratorTransactionsV3ToReport(
	collaboratorId: string,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	// add total values of transactions
	const { data } = await api.get<TransactionsResponseV2>(
		`/api/v1/cs/transactions/v3/${collaboratorId}/q`,
		{
			params: {
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function resendAccessEmail(collaboratorId: string) {
	const { data } = await api.post(`/api/v1/cs/email/${collaboratorId}`);
	return data;
}

export async function sendForgotPasswordEmail(
	collaboratorEmail: string,
	collaboratorId: string
) {
	const { data } = await api.post(`/api/v1/cs/collaborators/password/forgot`, {
		email: collaboratorEmail,
		id: collaboratorId,
	});
	return data;
}

interface DuplicateCards {
	collaborator_cpf: string;
	card_pan: string;
	activate_date: string;
}

export async function getFilteredDuplicateCards(collaborator_id: string) {
	const { data } = await api.get<DuplicateCards[]>(
		'/api/v1/cs/duplicate-cards-collabId/details/general/q',
		{
			params: {
				collaborator_id: collaborator_id,
			},
		}
	);
	return data;
}

export async function getCollaboratorBalances(collaborator_id: string) {
	const { data } = await api.get(
		`/api/v1/cs/collaborators/${collaborator_id}/balances`
	);

	return data;
}
