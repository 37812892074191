import styled from 'styled-components';

interface Props {
	text: string;
	width?: string;
	fontSize?: string;
	fontColor?: string;
	children?: React.ReactNode;
}

export function TextWithHint({
	text,
	width = '20rem',
	fontSize,
	fontColor,
	children,
}: Props) {
	return (
		<TextContainer>
			<span
				style={{ maxWidth: width, fontSize: fontSize, color: fontColor }}
				data-rh={text}
			>
				{text}
			</span>
			{children}
		</TextContainer>
	);
}

export const TextContainer = styled.div`
	display: flex;
	width: fit-content;
	gap: 0.5rem;
	align-items: center;
	& > span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
