export function cnpjMask(value: string): string {
  if (value.length <= 18) {
    value = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  }
  return value.substring(0, 18);
}

export function parseMaskedCnpjToRaw(maskedCnpj: string) {
  return maskedCnpj.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "");
}
