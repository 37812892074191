import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IoReload } from 'react-icons/io5';

export const TableStyles = css`
	width: 100%;
	margin: 1rem 0;
	font-size: 1.6rem;
`;

export const HeaderCellStyles = css`
	text-align: start;
	color: #7e7e7e;
	border-bottom: 0.3rem solid var(--light-gray);
	padding: 1rem;
`;

export const DataStyles = css`
	color: var(--black);
	padding: 1rem;
	border-bottom: 0.2rem solid rgba(198, 198, 198, 0.5);
`;

export const IconContainer = styled.span`
	background-color: #dff3ff;
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin-right: 1rem;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--dark-gray);
	}
`;

export const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	> svg {
		width: 2rem;
		height: 2rem;
	}
	> span {
		margin-left: 1rem;
	}
`;

export const Container = styled.div`
	flex: 1;
	padding: 2rem;
`;

export const Table = styled.table`
	${TableStyles}
	margin-bottom: 4rem;
`;
export const TableHeader = styled.thead`
	text-transform: uppercase;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHeaderCell = styled.th`
	${HeaderCellStyles}
	white-space: nowrap;
`;
export const TableData = styled.td`
	${DataStyles}
	min-width: 13rem;

	& > span {
		display: flex;
		flex-direction: column;
	}
`;

export const TableHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const PaginationContainer = styled.div`
	display: flex;
	margin-left: auto;
	align-items: center;
	& > button {
		background: none;
		margin-left: 1rem;
		display: flex;
		align-items: center;
		& > svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
`;

export const TableText = styled.h2``;

export const TitleWrapper = styled.div`
	display: flex;
`;

export const Option = styled(Link)`
	color: var(--white);
	background-color: var(--primary-blue);
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
	}
`;

export const ReloadIcon = styled(IoReload)`
	display: flex;
	align-items: center;
	color: var(--primary-blue);
	font-size: 3rem;
	padding: 0.5rem;
	border-radius: 50%;
	border: 1px solid var(--primary-blue);
	margin-left: 2rem;
	cursor: pointer;
	&:hover {
		filter: brightness(0.8);
	}
`;
