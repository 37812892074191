import React, { useEffect } from 'react';
import { GlobalTheme } from './styles/GlobalTheme';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './contexts/AuthContext';
import 'react-hint/css/index.css';
import ReactHintFactory from 'react-hint';
import { GlobalDebug } from './utils/removeConsoles';
import Footer from './components/Footer';
import { useLocation } from 'react-router';
import { useHistoryNonMatchCallbackStore } from './stores/useHistoryNonMatchCallbackStore';
import 'react-tabs/style/react-tabs.css';
import { NotificationProvider } from './contexts/NotificationsContext';

const ReactHint = ReactHintFactory(React);

function App() {
	const queryClient = new QueryClient();

	useEffect(() => {
		process.env.REACT_APP_NODE_ENV === 'production' && GlobalDebug(false);
	}, []);

	const location = useLocation();
	const onLocationChange = useHistoryNonMatchCallbackStore(
		(state) => state.onLocationChange
	);

	// whenever locations are changed call onLocationChange that holds logic whether useHistoryNonMatchCallbackStore
	// should reset page filters or not
	useEffect(() => {
		onLocationChange(location.pathname);
	}, [location.pathname, onLocationChange]);

	return (
		<QueryClientProvider client={queryClient}>
			<ReactHint autoPosition={true} events />
			<AuthProvider>
				<NotificationProvider>
					<Routes />
					<Footer />
					<ToastContainer />
					<GlobalTheme />
				</NotificationProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
}

export default App;
