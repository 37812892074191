import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

export const TabListContainer = styled(TabList)`
	font-size: 1.5rem;
	border-bottom: solid 2px var(--primary-blue);
	margin-bottom: 1rem;
`;

export const TabCustomized = styled(Tab)`
	display: inline-block;
	border: ${(props) =>
		props.selected ? '1px solid var(--primary-blue)' : '1px solid transparent'};
	border-bottom: none;
	bottom: -1px;
	position: relative;
	list-style: none;
	padding: 6px 12px;
	cursor: pointer;
	color: ${(props) => (props.selected ? '#FFF' : 'var(--primary-blue)')};
	font-weight: bold;

	background-color: ${(props) =>
		props.selected ? 'var(--primary-blue)' : 'transparent'};
`;
