import { Card } from '../../@types/CorporateExpenses/Card';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import api from '../api';

export const CORPWAY_CARDS_KEY = 'CORPWAY_CARDS_SERVICE_KEY';

interface CardsListReturn {
	cards: Card[];
	totalCards: number;
}
export async function getCards(page = 1, filterParams: FilterParams[]) {
	const { data } = await api.get<CardsListReturn>(
		`/api/v1/cs/corpway/companies/cards/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
			},
		}
	);
	return data;
}

export async function getCardMovements(
	company_id: string,
	card_id: string,
	selectedMonth: string
) {
	const { data } = await api.get(
		`/api/v1/cs/corpway/${company_id}/moviments/card/${card_id}/q`,
		{
			params: {
				month: selectedMonth,
			},
		}
	);
	return data;
}
