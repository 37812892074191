import { Header } from '../../components/Header';
import MainRoutes from '../../routes/MainRoutes';
import * as S from './styles';
import { SidebarLink } from '../../components/SidebarLink';
import { useLocation } from 'react-router-dom';
import { FaBuilding, FaCreditCard, FaUserFriends } from 'react-icons/fa';
import { BiSpreadsheet, BiUserMinus, BiUserPlus } from 'react-icons/bi';
import { AiOutlineSchedule } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { SidebarDropdown } from '../../components/SidebarDropdown';

export function Main() {
	const { pathname } = useLocation();

	return (
		<S.Container>
			<Header />

			<S.MainContent>
				<S.Sidebar>
					<S.CustomLabelStyled label='multiflex'>
						{' '}
						Benefícios Flexíveis{' '}
					</S.CustomLabelStyled>
					<SidebarLink
						icon={FaUserFriends}
						title='Colaboradores'
						path={`/collaborators`}
						currentPage={pathname === '/collaborators'}
					/>
					<SidebarLink
						icon={FaUserFriends}
						title='Migração de Colaboradores'
						path={`/collaborators-migrations`}
						currentPage={pathname.includes('/collaborators-migrations')}
					/>
					<SidebarLink
						icon={BiSpreadsheet}
						title='Grupos Corporativos'
						path={`/group-companies`}
						currentPage={pathname.includes('/group-companies')}
					/>
					<SidebarLink
						icon={FaBuilding}
						title='Empresas'
						path={`/companies`}
						currentPage={pathname === '/companies'}
					/>
					<SidebarLink
						icon={AiOutlineSchedule}
						title='Pagamentos'
						path={`/releases`}
						currentPage={pathname === '/releases'}
					/>
					<SidebarLink
						icon={HiOutlineDocumentReport}
						title='Relatório Operacional'
						path={`/report`}
						currentPage={pathname === '/report'}
					/>
					{/* 
						<SidebarLink
							icon={BiTransfer}
							title='Transações'
							path={`/transactions`}
							currentPage={pathname === '/transactions'}
						/>
					*/}
					<SidebarDropdown
						title='Solicitações'
						icon={FaUserFriends}
						subMenuItems={[
							{
								path: '/requests_pending',
								title: 'Solicitações de exclusão pendentes',
								icon: BiUserPlus,
							},
							{
								path: '/requests_accepts',
								title: 'Histórico de solicitações de exclusão',
								icon: BiUserMinus,
							},
						]}
					/>

					<S.CustomLabelStyled label='corpway'> Control </S.CustomLabelStyled>
					<SidebarLink
						icon={FaCreditCard}
						title='Cartões'
						path={`/corpway-cards`}
						currentPage={pathname.includes('/corpway-cards')}
					/>
				</S.Sidebar>
				<S.PagesContainer>
					<MainRoutes />
				</S.PagesContainer>
			</S.MainContent>
		</S.Container>
	);
}
