import styled from "styled-components";
import { OptionsButton } from "../Button";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80rem;
  height: 100%;
  font-size: 1.4rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.2rem solid var(--light-gray);
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
`;

export const FieldSet = styled.fieldset`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border: none;
`;

export const FieldGroup = styled.div`
  display: flex;
  width: 100%;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0.5rem 1.2rem 0.5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: var(--dark-gray);
  font-weight: 600;
`;

export const Input = styled.input`
  width: 100%;
  height: 3rem;
  color: var(--dark-gray);
  background-color: rgba(198, 198, 198, 0.33);
  border-radius: 0.4rem;
  border: none;
  padding: 0 1rem;
`;

export const InputTextArea = styled.textarea`
  width: 100%;
  color: var(--dark-gray);
  background-color: rgba(198, 198, 198, 0.33);
  border-radius: 0.4rem;
  border: none;
  padding: 0.4rem;
  min-height: 15rem;
  min-width: 45rem;

  &::-webkit-scrollbar {
    width: 1.2rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1rem;
    background-color: #e3e8eb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--light-gray);
    border-radius: 1rem;
  }
`;

export const SelectInput = styled.select`
  width: 100%;
  height: 3rem;
  background-color: rgba(198, 198, 198, 0.33);
  border-radius: 0.4rem;
  border: 0.1rem solid var(--light-gray);
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background: var(--light-gray);
  margin: 2rem 0 2rem 0;
`;

export const ButtonsContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
`;

export const LeftButtonsContainer = styled.div`
  display: flex;
  column-gap: 1rem;
`;

export const FormButton = styled.button`
  ${OptionsButton}
  width: 16rem;
`;

export const Option = styled.button`
  ${OptionsButton}
  background-color: transparent;
  color: var(--primary-blue);
  transition: all 0.2s;
  &:hover {
    background-color: var(--primary-blue);
    color: var(--white);
  }
`;

export const ImageUploadContainer = styled.div`
  display: flex;
  column-gap: 2rem;
`;

export const UploadIcon = styled.label`
  & svg {
    color: white;
    height: 6rem;
    width: 6rem;
    padding: 5px;
    border-radius: 0.8rem;
    background-color: var(--light-gray);
  }
`;

export const ImagePreview = styled.img`
  height: 6rem;
  width: 6rem;
  border-radius: 0.8rem;
  object-fit: contain;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  color: var(--dark-gray);
  background-color: rgba(198, 198, 198, 0.33);
  border-radius: 0.4rem;
  border: none;
  padding: 0 1rem;
`;
