import styled from 'styled-components';

export const MainButton = styled.button`
	height: min-content;
	width: 20rem;
	border-radius: 0.4rem;
	border: 0.2rem solid var(--primary-blue);
	padding: 1.2rem 2rem;
	font-weight: bold;
	background-color: var(--primary-blue);
	color: var(--white);
	margin: 0 auto;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 60rem;
	width: 70vw;
	padding-right: 1rem;
	min-height: 20rem;
`;

export const CollaboratorsList = styled.ul`
	display: flex;
	flex-direction: column;
	max-height: 50vh;
	overflow-y: auto;
	margin: 2rem 0;
`;

export const CollaboratorContainer = styled.li`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 0.1rem solid var(--primary-blue);
`;

export const CollaboratorCard = styled.div`
	display: flex;
	align-items: center;
`;

export const CollaboratorAvatar = styled.img`
	border: 0.2rem solid var(--primary-blue);
	border-radius: 50%;
	height: 5rem;
	width: 5rem;
	margin-right: 1rem;
	object-fit: cover;
`;

export const CollaboratorInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CollaboratorName = styled.span`
	color: var(--primary-blue);
	overflow: hidden;
	font-size: 1.6rem;
	text-overflow: ellipsis;
	display: inline-block;
	max-width: 500px;
`;

export const CollaboratorInfo = styled.span`
	font-size: 1.4rem;
	color: var(--light-gray);
`;

interface CollaboratorSelectionProps {
	selected: boolean;
}
export const Selector = styled.button<CollaboratorSelectionProps>`
	width: 3.5rem;
	height: 3.5rem;
	background: none;
	& svg {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		color: ${({ selected }) =>
			selected ? 'var(--light-gray)' : 'var(--primary-blue)'};
	}
`;
