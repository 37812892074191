import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	CardStatusTypeOptionsFilters,
	PaymentTypeOptionsFilters,
	ScheduleTypeOptionsFilters,
	TransactionTypeOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'Empresa',
		filter: 'company',
		value: '',
		type: 'company',
		selected: true,
		alwaysFilled: true,
	},
	{
		name: 'Nome',
		filter: 'name',
		value: '',
		type: 'text',
		selected: false,
	},
	{ name: 'CPF', filter: 'cpf', value: '', type: 'cpf', selected: false },
	{
		name: 'Recarga',
		filter: 'payment_status',
		value: '',
		type: 'select',
		selectOptions: PaymentTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Transação',
		filter: 'transaction_status',
		value: '',
		type: 'select',
		selectOptions: TransactionTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Agendamento',
		filter: 'schedule_status',
		value: '',
		type: 'select',
		selectOptions: ScheduleTypeOptionsFilters,
		selected: false,
	},
	{
		name: 'Status do cartão',
		filter: 'card_status',
		value: '',
		type: 'select',
		selectOptions: CardStatusTypeOptionsFilters,
		selected: false,
	},
];

export const useOperationalReportListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));
