import styled, { css } from 'styled-components';

export const Container = styled.div`
	background: var(--primary-blue);
	height: 6rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem;
	& > div {
		display: flex;
		align-items: center;
		column-gap: 2rem;
		height: 100%;
	}
`;

export const LogoImage = styled.img`
	width: 10rem;
	object-fit: contain;
	padding: 1rem 0;
`;

export const UserEmail = styled.span`
	color: var(--white);
	font-size: 1.4rem;
	text-decoration: underline;
`;

export const ProfileOptionButton = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	text-align: start;
	color: var(--white);
	font-size: 1.4rem;
	background-color: transparent;
	& svg {
		margin-right: 0.6rem;
		height: 2rem;
		width: 2rem;
	}
`;

export const DropdownContainerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;
	margin-top: 0.5rem;
	background-color: var(--white);
	border-radius: 0.8rem;
	min-width: 25rem;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

	& a,
	button {
		&:hover {
			filter: none;
		}
	}
`;

export const DropdownNotificationsContainer = styled.div`
	${DropdownContainerStyles}
	padding: 0;
	background-color: #f5f5f5;

	padding-right: 0.2rem;
	border: 0.24rem solid #85d6ff;
`;

export const IconBtn = styled.button`
	display: flex;
	background: none;
	border: none;
	border-radius: 50%;
	color: var(--white);
	font-size: 2.5rem;
	padding: 0.6rem;
	position: relative;
	& span {
		position: absolute;
		font-size: 1.4rem;
		font-weight: bolder;
		right: 0;
		background-color: var(--primary-purple);
		min-width: 2.2rem;
		max-width: 3rem;
		aspect-ratio: 1 / 1;
		padding: 0.2rem;
		border-radius: 50%;
		border: 0.1rem solid var(--white);
		margin-right: -1rem;
		top: 0;
		margin-top: -0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
