import { Link } from 'react-router-dom';
import { Company } from '../../../../../@types/Company';
import { cnpjMask } from '../../../../../utils/masks/cnpj';
import { cpfMask } from '../../../../../utils/masks/cpf';
import { phoneMask } from '../../../../../utils/masks/phone';
import * as S from '../styles';
import { EmptyContent } from '../../../../../components/EmptyContent';

interface Props {
	companyData: Company;
}

const shareholderType = {
	partner: 'Sócio',
	proxyholder: 'Procurador',
	legal_representative: 'Representante legal',
	other: 'Outro',
};

export function CompanyRegistrationData({ companyData }: Props) {
	return (
		<>
			<S.GridContainer>
				<S.InfoContainer>
					<S.InfoTitle>Grupo Corporativo</S.InfoTitle>
					<Link to={`/group-companies/${companyData.corporate_group_id}`}>
						{companyData.corporate_group?.name}
					</Link>
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Razão social</S.InfoTitle>
					{companyData.name}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Email</S.InfoTitle>
					{companyData.email}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Email do setor financeiro</S.InfoTitle>
					{companyData.financial_sector_email ?? 'N/A'}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Nome fantasia</S.InfoTitle>
					{companyData.corporate_name}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>CNPJ</S.InfoTitle>
					{cnpjMask(companyData.cnpj)}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Data de fundação</S.InfoTitle>
					{companyData.founding_date}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Tipo de negócio</S.InfoTitle>
					{companyData.business_type}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Número do CNAE</S.InfoTitle>
					{companyData.main_activity}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Website</S.InfoTitle>
					{companyData.website ? companyData.website : 'N/A'}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Logo</S.InfoTitle>
					<S.InfoImage
						src={companyData.avatar_url}
						alt={`Logo da empresa ${companyData.name}`}
					/>
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Endereço</S.InfoTitle>
					{companyData.address}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Número</S.InfoTitle>
					{companyData.number}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Bairro</S.InfoTitle>
					{companyData.district}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>CEP</S.InfoTitle>
					{companyData.cep}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Complemento</S.InfoTitle>
					{companyData.complement ? companyData.complement : 'N/A'}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Cidade</S.InfoTitle>
					{companyData.city}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>UF</S.InfoTitle>
					{companyData.uf}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Telefone 1</S.InfoTitle>
					{phoneMask(companyData.first_phone)}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoTitle>Telefone 2</S.InfoTitle>
					{companyData.second_phone
						? phoneMask(companyData.second_phone)
						: 'N/A'}
				</S.InfoContainer>
			</S.GridContainer>

			<div
				style={{
					width: '100%',
					height: '0.1rem',
					background: 'var(--light-gray)',
					margin: '3rem 0',
				}}
			/>

			{companyData.shareholders?.length ? (
				companyData.shareholders.map((shareholder, index) => {
					return (
						<S.ShareholderContainer key={shareholder.id}>
							<S.SectionTitle>Sócio #{index + 1}</S.SectionTitle>
							<S.ShareholderGridContainer>
								<S.InfoContainer>
									<S.InfoTitle>Nome</S.InfoTitle>
									{shareholder.first_name}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Sobrenome</S.InfoTitle>
									{shareholder.last_name}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Tipo</S.InfoTitle>
									{
										shareholderType[
											shareholder.type as keyof typeof shareholderType
										]
									}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Nome da mãe</S.InfoTitle>
									{shareholder.mother_name}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>CPF</S.InfoTitle>
									{cpfMask(shareholder.cpf)}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Data de nascimento</S.InfoTitle>
									{shareholder.birth_date}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Email</S.InfoTitle>
									{shareholder.email}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Telefone</S.InfoTitle>
									{phoneMask(shareholder.phone_number)}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Endereço</S.InfoTitle>
									{shareholder.address}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Número</S.InfoTitle>
									{shareholder.number}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Bairro</S.InfoTitle>
									{shareholder.district}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>CEP</S.InfoTitle>
									{shareholder.cep}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Complemento</S.InfoTitle>
									{shareholder.complement === ''
										? 'N/A'
										: shareholder.complement}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Cidade</S.InfoTitle>
									{shareholder.city}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>UF</S.InfoTitle>
									{shareholder.uf}
								</S.InfoContainer>
							</S.ShareholderGridContainer>
						</S.ShareholderContainer>
					);
				})
			) : (
				<div
					style={{
						height: '13rem',
						alignContent: 'center',
					}}
				>
					<EmptyContent big text='Não há sócios cadastrados.' />
				</div>
			)}
		</>
	);
}
