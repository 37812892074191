import { useMutation, useQuery } from 'react-query';
import Loader from '../../../../../../../components/Loader';
import {
	getFilteredDuplicateCards,
	resendAccessEmail,
} from '../../../../../../../services/queries/Collaborators';
import { ResendPasswordEmailModal } from '../../ResendPasswordEmailModal';
import * as S from '../styles';
import { showErrorMessage } from '../../../../../../../utils/ErrorHandler';
import { toast } from 'react-toastify';
import { cpfMask } from '../../../../../../../utils/masks/cpf';
import { phoneMask } from '../../../../../../../utils/masks/phone';
import { parseKYCStatusString } from '../../../../../../../utils/status/parseKycStatus';
import { useState } from 'react';
import { Props } from '../..';

interface DuplicateCards {
	collaborator_cpf: string;
	card_pan: string;
	activate_date: string;
}

export function GeneralData({ collaborator }: Props) {
	const [duplicateCard, setDuplicateCard] = useState<DuplicateCards[]>();

	const resendAccessEmailQuery = useMutation(
		() => resendAccessEmail(collaborator.id),
		{
			onSuccess: () => {
				toast.info('Email de primeiro acesso reenviado com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar reeviar o email de acesso ao colaborador.'
				);
			},
		}
	);

	const duplicateCardsStatsQuery = useQuery(
		['duplicateCardsStats', collaborator.id],
		() => {
			return getFilteredDuplicateCards(collaborator.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os quantitativos de segundas vias de cartão. '
				);
			},
			onSuccess: (data) => {
				setDuplicateCard(data);
			},
		}
	);

	const formatDate = (activate_date: string) => {
		const date = new Date(activate_date);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	};

	const copyIdToClipboard = (AH_ID: string, BAG_ID: string, TAH_ID: string) => {
		const idString = `AH_ID = ${AH_ID}\nBAG_ID = ${BAG_ID}\nTAH_ID = ${TAH_ID}`;
		navigator.clipboard.writeText(idString);
	};

	const handleCopyClick = () => {
		copyIdToClipboard(
			collaborator.id_baas,
			collaborator.id_baas_wallet,
			collaborator.company_id_baas
		);

		toast.info('IDs copiados para a área de transferência.');
	};

	if (duplicateCardsStatsQuery.isLoading || duplicateCardsStatsQuery.isError)
		return (
			<S.GridContainer>
				<Loader />
			</S.GridContainer>
		);

	return (
		<div>
			<S.GridContainer>
				<S.InfoContainer>
					<S.InfoLabel>ID Usuário SWAP</S.InfoLabel>
					{collaborator.id_baas ?? 'N/A'}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>ID Conta SWAP</S.InfoLabel>
					{collaborator.id_baas_wallet ?? 'N/A'}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>ID Empresa SWAP</S.InfoLabel>
					{collaborator.company_id_baas ?? 'N/A'}
				</S.InfoContainer>

				<S.InfoContainer>
					<S.InfoLabel>STATUS do KYC</S.InfoLabel>
					{parseKYCStatusString(collaborator.kyc_status)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Nome</S.InfoLabel>
					{`${collaborator.first_name} ${collaborator.last_name}`}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Nome da mãe</S.InfoLabel>
					{collaborator.mother_name}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>CPF</S.InfoLabel>
					{cpfMask(collaborator.cpf)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Data de nascimento</S.InfoLabel>
					{collaborator.birth_date.toString()}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Email</S.InfoLabel>
					{collaborator.email}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>2º Via cartão</S.InfoLabel>
					{duplicateCard && duplicateCard.length > 0 ? (
						<>
							{duplicateCard?.map((item) => (
								<S.DuplicateCard key={item.card_pan}>
									{item.card_pan} - Vinculado em{' '}
									{formatDate(item.activate_date)}
									<br />
								</S.DuplicateCard>
							))}
						</>
					) : (
						<span data-testid='duplicateCardContainer'>N/A</span>
					)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Telefone 1</S.InfoLabel>
					{phoneMask(collaborator.first_phone)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoLabel>Telefone 2</S.InfoLabel>
					{phoneMask(collaborator.second_phone)}
				</S.InfoContainer>
			</S.GridContainer>

			<S.BttnContainer>
				<S.CopyIdBtn onClick={handleCopyClick}>Copiar IDs</S.CopyIdBtn>
			</S.BttnContainer>

			{collaborator.status_password && (
				<S.BttnContainer>
					<ResendPasswordEmailModal collaborator={collaborator} />
				</S.BttnContainer>
			)}

			{collaborator.kyc_status === 'approved' &&
				!collaborator.status_password &&
				collaborator.status && (
					<S.BttnContainer>
						<S.SendEmailBtn
							onClick={() => resendAccessEmailQuery.mutate()}
							disabled={resendAccessEmailQuery.isLoading}
						>
							{resendAccessEmailQuery.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'Reenviar email de primeiro acesso'
							)}
						</S.SendEmailBtn>
					</S.BttnContainer>
				)}
		</div>
	);
}
