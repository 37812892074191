import logoImg from '../../assets/Bounty.svg';
import * as S from './styles';

export default function NotFound() {
	return (
		<S.Container>
			<S.LogoContainer>
				<S.LogoImage src={logoImg} alt='Logo do Bounty' />
				Customer Support
			</S.LogoContainer>

			<S.StyledPhrase> 404 - PÁGINA NÃO ENCONTRADA </S.StyledPhrase>
		</S.Container>
	);
}
