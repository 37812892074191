import { useParams } from 'react-router-dom';
import PageTitle from '../../../../components/PageTitle';
import * as S from './styles';
import { ReleasesTab } from './ReleaseDetailsTabs';
import { useQuery } from 'react-query';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { fetchReleaseDetails } from '../../../../services/queries/Releases';
import { ReleaseDetails } from '../../../../@types/Release';
import Loader from '../../../../components/Loader';
import { PaymentHeader } from './PaymentHeader';

export function ReleasesDetails() {
	const { id } = useParams();

	const fetchReleaseDetailsQuery = useQuery<ReleaseDetails, Error>(
		['fetchReleaseDetails', id],
		() => {
			return fetchReleaseDetails(id!);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar informações do pagamento.'
				);
			},
		}
	);

	if (fetchReleaseDetailsQuery.isLoading || !fetchReleaseDetailsQuery.data) {
		return (
			<S.Container>
				<PageTitle title={`Detalhes do Pagamento `} />
				<Loader />
			</S.Container>
		);
	}
	return (
		<S.Container>
			<PageTitle title={`Detalhes do Pagamento `} />
			<PaymentHeader title='' release={fetchReleaseDetailsQuery.data} />
			<ReleasesTab releaseDetails={fetchReleaseDetailsQuery.data} />
		</S.Container>
	);
}
