import * as S from './styles';

interface CompanyCardProps {
	name: string;
	bottomInfo: string;
	id?: string;
}

export default function CompanyCard({ name, bottomInfo }: CompanyCardProps) {
	return (
		<S.Container>
			<S.InfoContainer>
				<span>{name} </span>
				<p>{bottomInfo}</p>
			</S.InfoContainer>
		</S.Container>
	);
}
