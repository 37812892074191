import { useContext } from 'react';
import {
	NotificationContext,
	NotificationData,
} from '../contexts/NotificationsContext';

export function useNotifications(): NotificationData {
	const context = useContext(NotificationContext);

	return context;
}
