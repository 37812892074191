import { GroupCompany } from '../../@types/GroupCompany';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface CompaniesResponse {
	totalCorpGroups: number;
	corpGroups: GroupCompany[];
}

export async function getGroupCompanies(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<CompaniesResponse>(
		`/api/v1/cs/group-companies/q`,
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
				...parseSortColumnToParams(sortColumn),
			},
		}
	);

	return data;
}

export async function fetchGroupCompany(
	groupCompanyId: string
): Promise<GroupCompany> {
	const { data } = await api.get<GroupCompany>(
		`/api/v1/cs/group-companies/${groupCompanyId}`
	);

	return data;
}

export async function getGroupCompaniesSelect(): Promise<GroupCompany[]> {
	const { data } = await api.get('/api/v1/cs/group-companies/select');

	return data;
}
