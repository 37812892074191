import { useForm } from 'react-hook-form';
import logoImg from '../../assets/Bounty.svg';
import * as S from './styles';
import Loader from '../../components/Loader';
import { PasswordInput } from '../../components/PasswordInput';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

type FormData = {
	email: string;
	password: string;
};

export default function SignIn() {
	const { signIn } = useAuth();
	const navigate = useNavigate();
	const { register, handleSubmit, formState } = useForm<FormData>();
	const { isSubmitting } = formState;

	async function handleSignIn({ email, password }: FormData) {
		if (!email || !password) return;

		const loginSuccessful = await signIn({
			email: email,
			password: password,
		});

		if (loginSuccessful) navigate('/');
	}

	return (
		<S.Container>
			<S.LogoContainer>
				<S.LogoImage src={logoImg} alt='Logo do Bounty' />
				Customer Support
			</S.LogoContainer>

			<S.Form onSubmit={handleSubmit(handleSignIn)} autoComplete='off'>
				<S.ContentForm>
					<S.FormInput
						type='email'
						placeholder='Email'
						{...register('email')}
						required
					/>

					<PasswordInput
						style={{ height: '3.6rem' }}
						name='password'
						register={register}
						required
					/>
				</S.ContentForm>

				<S.SubmitButton disabled={isSubmitting} type='submit'>
					{!isSubmitting ? 'ENTRAR' : <Loader color='#fff' size={20} />}
				</S.SubmitButton>
			</S.Form>
		</S.Container>
	);
}
