import { Release } from '../../@types/Release';
import { ReleaseDetails } from '../../@types/Release';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../hooks/useSortColumnHook';
import api from '../api';

interface ReleaseResponse {
	releases: Release[];
	totalReleases: number;
}

export async function getReleases(
	page = 1,
	filterParams: FilterParams[],
	sortColumn: SortColumn | null
) {
	const { data } = await api.get<ReleaseResponse>(`/api/v1/cs/releases/q`, {
		params: {
			page,
			...parseFilterParamsToParams(filterParams),
			...parseSortColumnToParams(sortColumn),
		},
	});

	return data;
}

export async function fetchReleaseDetails(
	release_id: string
): Promise<ReleaseDetails> {
	const { data } = await api.get<ReleaseDetails>(
		`/api/v1/cs/releases/${release_id}`
	);
	return data;
}
