import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import { TabPanel, Tabs } from 'react-tabs';
import { Company } from '../../../../@types/Company';
import Modal from '../../../../components/Modal';
import PageTitle from '../../../../components/PageTitle';
import { CompanyCostCenters } from './CompanyDetailsTabs/CompanyCostCenters';
import { CompanyEnabledBenefits } from './CompanyDetailsTabs/CompanyEnabledBenefits';
import { CompanyEnabledPartners } from './CompanyDetailsTabs/CompanyEnabledPartners';
import { CompanyRegistrationData } from './CompanyDetailsTabs/CompanyRegistrationData';
import * as S from './styles';
import * as TabStyle from '../../../../components/Tab/TabStyles';
import { CompanyFundsIn } from './CompanyDetailsTabs/CompanyFundsIns';
import { CompanyBalance } from './CompanyDetailsTabs/CompanyBalance';

interface Props {
	company: Company;
}

export function CompanyDetailsModal({ company }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-rh='Detalhamento'
				data-testid='openButton_test_id'
			>
				<MdNotes />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						<PageTitle title='Detalhamento de empresa' />
					</S.TitleWrapper>

					<Tabs>
						<TabStyle.TabListContainer>
							<TabStyle.TabCustomized>Dados Cadastrais</TabStyle.TabCustomized>

							<TabStyle.TabCustomized>
								Benefícios Habilitados
							</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>
								Parcerias Habilitadas
							</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>Centros de custo</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>Recargas</TabStyle.TabCustomized>
							<TabStyle.TabCustomized>Saldos</TabStyle.TabCustomized>
						</TabStyle.TabListContainer>

						<TabPanel>
							<CompanyRegistrationData companyData={company} />
						</TabPanel>

						<TabPanel>
							<CompanyEnabledBenefits benefits={company.benefits!} />
						</TabPanel>

						<TabPanel>
							<CompanyEnabledPartners partners={company.partners!} />
						</TabPanel>

						<TabPanel>
							<CompanyCostCenters
								AllCostCenters={{
									costCenters: company.costCenters!,
									corpwaycostCenters: company.corpwayCostCenters!,
								}}
							/>
						</TabPanel>

						<TabPanel>
							<CompanyFundsIn company={company} />
						</TabPanel>

						<TabPanel>
							<CompanyBalance company={company} />
						</TabPanel>
					</Tabs>
				</S.Container>
			</Modal>
		</>
	);
}
