import Loader from '../../../../../components/Loader';
import * as S from './styles';
import * as TableStyle from '../../../../../components/Table/TableStyles';
import { getBenefitIcon } from '../../../../../utils/benefits/getBenefitIcon';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { useQuery } from 'react-query';
import { getCollaboratorBalances } from '../../../../../services/queries/Collaborators';
import { useState } from 'react';
import { FaWallet } from 'react-icons/fa';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import { Props } from '../CollaboratorDetailsModal';

export interface BalanceData {
	benefit_balance: number;
	benefit_id: string;
	benefit_label: string;
}

export function BalanceDetailsModal({ collaborator }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const collaboratorBalanceQuery = useQuery<BalanceData[]>(
		['collaboratorBalance', collaborator.id],
		() => {
			return getCollaboratorBalances(collaborator.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar os balanços do colaborador. '
				);
			},
			onSuccess(data) {
				console.log(data);
			},
			enabled: isOpen,
		}
	);

	if (collaboratorBalanceQuery.isLoading || !collaboratorBalanceQuery.data) {
		return (
			<>
				<S.openModalBttn
					onClick={() => setIsOpen(true)}
					data-rh='Balanços'
					data-testid='openButton_test_id'
				>
					<FaWallet />
				</S.openModalBttn>

				<Modal
					isOpen={isOpen}
					onRequestClose={() => setIsOpen(false)}
					enableClose
				>
					<S.Container>
						<S.TitleWrapper>
							<PageTitle
								title={`Balanços do colaborador: ${collaborator.first_name} ${collaborator.last_name}`}
							/>
						</S.TitleWrapper>
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-rh='Balanços'
				data-testid='openButton_test_id'
			>
				<FaWallet />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						<PageTitle
							title={`Balanços do colaborador: ${collaborator.first_name} ${collaborator.last_name}`}
						/>
					</S.TitleWrapper>

					<TableStyle.Table>
						<TableStyle.TableHeader>
							<TableStyle.TableRow>
								<TableStyle.TableHeaderCell>TÍTULO</TableStyle.TableHeaderCell>
								<TableStyle.TableHeaderCell>VALOR</TableStyle.TableHeaderCell>
							</TableStyle.TableRow>
						</TableStyle.TableHeader>

						<TableStyle.TableBody>
							{!collaboratorBalanceQuery.data.length ? (
								<EmptyContent big />
							) : (
								collaboratorBalanceQuery.data
									.filter((balance) => balance) // filtering out the null items received
									.map((balance) => (
										<TableStyle.TableRow key={balance.benefit_id}>
											<TableStyle.TableData>
												<S.TitleContainer>
													{getBenefitIcon(balance.benefit_label)}{' '}
													{getBenefitParsedTitle(balance.benefit_label)}
												</S.TitleContainer>
											</TableStyle.TableData>
											<TableStyle.TableData>
												{convertCentsToReais(Number(balance.benefit_balance))}
											</TableStyle.TableData>
										</TableStyle.TableRow>
									))
							)}
						</TableStyle.TableBody>
					</TableStyle.Table>
				</S.Container>
			</Modal>
		</>
	);
}
