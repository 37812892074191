import * as TableStyles from '../../../../components/Table/TableStyles';
import { EmptyContent } from '../../../../components/EmptyContent';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import { TextWithHint } from '../../../../components/TextWithHint';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook/index';
import { Operator } from '../../../../@types/Operator';

interface Props {
	operators: Operator[];
}
export function OperatorsTab({ operators }: Props) {
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	function parseAccessLevel(access_level: string) {
		if (access_level === 'operator') {
			return 'Operador';
		} else if (access_level === 'super_admin') {
			return 'Super Admin';
		} else if (access_level === 'admin') {
			return 'Admin';
		}

		return '???';
	}

	if (operators.length === 0) return <EmptyContent big />;

	return (
		<TableStyles.Table>
			<TableStyles.TableHeader>
				<TableStyles.TableRow>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'name'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('name')}
						/>
						NOME
					</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'email'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('email')}
						/>
						EMAIL
					</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'created_at'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('created_at')}
						/>
						CRIAÇÃO
					</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>
						NÍVEL DE ACESSO
					</TableStyles.TableHeaderCell>
				</TableStyles.TableRow>
			</TableStyles.TableHeader>

			<TableStyles.TableBody>
				{sortList(operators).map((operator) => (
					<TableStyles.TableRow key={operator.id}>
						<TableStyles.TableData>
							<TextWithHint text={operator.name} width='100%' />
						</TableStyles.TableData>
						<TableStyles.TableData>
							<TextWithHint text={operator.email} width='100%' />
						</TableStyles.TableData>
						<TableStyles.TableData>
							{new Date(operator.created_at!).toLocaleDateString()}
						</TableStyles.TableData>
						<TableStyles.TableData>
							{parseAccessLevel(operator.access_level)}
						</TableStyles.TableData>
					</TableStyles.TableRow>
				))}
			</TableStyles.TableBody>
		</TableStyles.Table>
	);
}
