import styled from 'styled-components';

export const Container = styled.div``;

export const cnpjText = styled.span`
	width: max-content;
	white-space: nowrap;
`;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
`;
