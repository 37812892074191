import { MdNotes } from 'react-icons/md';
import { RequestDeleteUsersCollaborator } from '../../../../../@types/RequestDeleteUsersCollaborator';
import * as S from './styles';
import * as TabStyle from '../../../../../components/Tab/TabStyles';
import { useState } from 'react';
import Modal from '../../../../../components/Modal';
import PageTitle from '../../../../../components/PageTitle';
import { TabPanel, Tabs } from 'react-tabs';
import { RequestData } from './RequestDetailsTabs/RequestData';
import { deleteUserCollaborator } from '../../../../../services/queries/RequestDeleteUsersCollaborator';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import FeedbackModal from '../FeedBackModal';

interface Props {
	request: RequestDeleteUsersCollaborator;
}

export function RequestDeleteUserCollaboratorModal({ request }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const queryClient = useQueryClient();

	const deleteUsersCollaborator = useMutation(
		async (deletionReason: string) => {
			await deleteUserCollaborator(
				request.user_id,
				request.company_id,
				request.id,
				request.reason_deletion,
				deletionReason
			);
		},
		{
			onSuccess: () => {
				toast.info('Solicitação revisada com sucesso!');
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar revisar a solicitação.'
				);
			},
		}
	);

	async function handleReviewDeletationRequest(deletionReason: string) {
		await deleteUsersCollaborator.mutateAsync(deletionReason);
		queryClient.resetQueries('requestPendingDeleteCollabList');
		setIsOpen(false);
	}

	return (
		<>
			<S.openModalBttn
				onClick={() => setIsOpen(true)}
				data-rh='Detalhamento'
				data-testid='openButton_test_id'
			>
				<MdNotes />
			</S.openModalBttn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						<PageTitle
							title={`Dados da solicitação de exclusão: ${request.user.first_name} ${request.user.last_name}`}
						/>
					</S.TitleWrapper>
					<Tabs>
						<TabStyle.TabListContainer>
							<TabStyle.TabCustomized>Dados Gerais</TabStyle.TabCustomized>
						</TabStyle.TabListContainer>

						<TabPanel>{<RequestData request={request} />}</TabPanel>
					</Tabs>

					{!request.reviwed && (
						<S.BttnContainer>
							<S.Bttn
								onClick={() => {
									handleReviewDeletationRequest(request.reason_reviwed);
								}}
								disabled={deleteUsersCollaborator.isLoading}
							>
								Aprovar exclusão
							</S.Bttn>

							<FeedbackModal
								onDeleteUser={(disapprovalReason) =>
									handleReviewDeletationRequest(disapprovalReason)
								}
							/>
						</S.BttnContainer>
					)}
				</S.Container>
			</Modal>
		</>
	);
}
