import { create } from 'zustand';
import { FilterParams } from '../components/Filter';
import {
	FinancialImpactOptionsFilters,
	ResponseTypesOptionsFilters,
	TxnTypesOptionsFilters,
} from '../components/Filter/utils/SelectFilters';

type Store = {
	filterParams: FilterParams[];
	setFiltersParams: (f: FilterParams[]) => void;
	resetFilters: () => void;
};

const filterParams: FilterParams[] = [
	{
		name: 'ID',
		filter: 'internal_transaction_id',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Status',
		filter: 'response_code',
		value: '',
		type: 'select',
		selectOptions: ResponseTypesOptionsFilters,
		selected: false,
	},
	{
		name: 'Data Tentativa',
		filter: 'month_period',
		value: '',
		type: 'month_period',
		selected: false,
	},
	{
		name: 'Estabelecimento',
		filter: 'merchant_name',
		value: '',
		type: 'text',
		selected: false,
	},
	{
		name: 'Tipo Transação',
		filter: 'txn_type',
		value: '',
		type: 'select',
		selectOptions: TxnTypesOptionsFilters,
		selected: false,
	},
	{
		name: 'Impacto Financeiro',
		filter: 'financial_impact',
		value: '',
		type: 'select',
		selectOptions: FinancialImpactOptionsFilters,
		selected: false,
	},
];

const useTransactionsListStore = create<Store>()((set) => ({
	filterParams,
	setFiltersParams: (f) => set({ filterParams: f }),
	resetFilters: () => set({ filterParams }),
}));

export { useTransactionsListStore };
