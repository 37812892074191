import styled from 'styled-components';

export const Container = styled.div`
	flex: 1;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	padding-bottom: 2rem;
`;

export const TotalRecords = styled.span`
	font-size: 1.4rem;
`;
