import styled from 'styled-components';

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;

export const Container = styled.div`
	flex: 1;
	width: 80vw;
	height: 80vh;
`;
export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;
export const GridContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1.6rem;
	width: 100%;
	overflow-x: auto;
	padding-bottom: 1rem;
`;

export const FlexContainer = styled.div`
	display: flex;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
`;

export const InfoTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--mid-gray);
	text-transform: uppercase;
	margin-bottom: 0.5rem;
`;

export const InfoImage = styled.img`
	max-width: 200px;
	max-height: 200px;
	border-radius: 0.8rem;
	object-fit: contain;
`;

export const SectionTitle = styled.h2`
	font-weight: 500;
	font-size: 1.8rem;
	color: var(--dark-gray);
	text-transform: uppercase;
	margin-bottom: 1rem;
`;

export const ShareholderGridContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 2.8rem;
`;

export const ShareholderContainer = styled.div`
	margin-top: 1rem;
	margin-bottom: 1rem;

	& + & {
		margin-top: 5rem;
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	& > img,
	svg {
		width: 2rem;
		height: 2rem;
		color: var(--dark-gray);
	}
`;
