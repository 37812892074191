export function parseStatus(status: boolean) {
	return status ? 'Ativado' : 'Desativado';
}

export function parseCardType(type: string){
	return type === 'physical' ? 'Físico' : 'Virtual'
}

export function parseLinkedStatus(status: boolean) {
	return status ? 'Vinculado' : 'Não-vinculado';
}

export function parseTransactionStatus(status: string) {
	return status === 'Approved' ? 'APROVADO' : 'NEGADO';
}
