import { Props } from '..';
import * as S from './styles';

export function EmploymentData({ collaborator }: Props) {
	function parseContractTypeToPortugues(contract: string) {
		if (contract === 'TRAINEE') {
			return 'Estágio';
		} else if (contract === 'CLT') return 'CLT';
		else if (contract === 'PJ') return 'PJ';
		else if (contract === 'SHAREHOLDER') return 'Sócio';
		return '??';
	}

	return (
		<S.GridContainer>
			<S.InfoContainer>
				<S.InfoLabel>Cargo</S.InfoLabel>
				{collaborator.office}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Setor de atuação</S.InfoLabel>
				{collaborator.section}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Contrato</S.InfoLabel>
				{parseContractTypeToPortugues(collaborator.contract_type)}
			</S.InfoContainer>
			<S.InfoContainer>
				<S.InfoLabel>Centro de Custo</S.InfoLabel>
				{collaborator.cost_center.title}
			</S.InfoContainer>
		</S.GridContainer>
	);
}
