import { ReleaseDetails } from '../../../../../@types/Release';
import { EmptyContent } from '../../../../../components/EmptyContent';
import { useSortColumnHook } from '../../../../../hooks/useSortColumnHook';
import * as TableStyles from '../../../../../components/Table/TableStyles';
import { SortColumnButton } from '../../../../../components/SortColumnButton';
import { TextWithHint } from '../../../../../components/TextWithHint';
import * as S from './styles';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import UserCard from '../../../../../components/UserCard';
import { cpfMask } from '../../../../../utils/masks/cpf';

interface Props {
	releaseDetails: ReleaseDetails;
}

type BenefitsPayment = { value: number; error: string | undefined };
type CollaboratorPayment = {
	name: string;
	cpf: string;
	benefits: Record<string, BenefitsPayment>;
};

export function ReleasesTab({ releaseDetails }: Props) {
	const { currentSortColumn, toggleSort, sortList } = useSortColumnHook();

	const collaborators: Record<string, CollaboratorPayment> = {};
	const benefits: Record<string, null> = {};

	if (!releaseDetails) return <EmptyContent />;

	releaseDetails.release.preReleases.forEach(
		({ collaborator, release_payment }) => {
			const { id, name, cpf } = collaborator;
			const { benefit, reason_disapproval } = release_payment;

			if (!collaborators[id]) {
				collaborators[id] = { name, cpf, benefits: {} };
			}

			if (benefit && benefit.title) {
				const { title, value } = benefit;
				collaborators[id].benefits[title] = {
					value,
					error: reason_disapproval,
				};
				benefits[title] = null;
			}
		}
	);

	function benefitStatus(reason: string) {
		if (reason) {
			return (
				<S.StatusBenefits>
					<FaInfoCircle />
					<span>{reason}</span>
				</S.StatusBenefits>
			);
		} else {
			return (
				<S.StatusBenefits>
					<FaCheckCircle color='#1CCA0D' />
				</S.StatusBenefits>
			);
		}
	}

	return (
		<TableStyles.Table>
			<TableStyles.TableHeader>
				<TableStyles.TableRow>
					<TableStyles.TableHeaderCell>Nº</TableStyles.TableHeaderCell>
					<TableStyles.TableHeaderCell>
						<SortColumnButton
							order={
								currentSortColumn?.name === 'name'
									? currentSortColumn.order
									: null
							}
							onToggleSort={() => toggleSort('name')}
						/>
						COLABORADOR
					</TableStyles.TableHeaderCell>

					{Object.keys(benefits).map((benefit, index) => (
						<TableStyles.TableHeaderCell key={index}>
							{benefit}
						</TableStyles.TableHeaderCell>
					))}
				</TableStyles.TableRow>
			</TableStyles.TableHeader>
			<TableStyles.TableBody>
				{!releaseDetails.release.preReleases.length && <EmptyContent />}
				{sortList(Object.values(collaborators)).map((collaborator, index) => (
					<TableStyles.TableRow key={index}>
						<TableStyles.TableData width='2rem'>
							<TextWithHint text={`${index + 1}`} width='14rem' />
						</TableStyles.TableData>
						<TableStyles.TableData>
							<UserCard
								name={collaborator.name}
								bottomInfo={cpfMask(collaborator.cpf)}
							/>
						</TableStyles.TableData>
						{Object.keys(benefits)
							.sort()
							.map((benefit, index) => (
								<TableStyles.TableData key={index}>
									<TextWithHint
										text={`${
											collaborator.benefits[benefit] &&
											collaborator.benefits[benefit].value !== 0
												? convertCentsToReais(
														collaborator.benefits[benefit].value
												  )
												: convertCentsToReais(0)
										}`}
										width='14rem'
									>
										{collaborator.benefits[benefit] &&
											benefitStatus(collaborator.benefits[benefit].error || '')}
									</TextWithHint>
								</TableStyles.TableData>
							))}
					</TableStyles.TableRow>
				))}
			</TableStyles.TableBody>
		</TableStyles.Table>
	);
}
