const UF_List = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const only_alphabeticals_with_space_regex = /[^a-zA-ZÁ-ÿ\u00f1\u00d1 ]/g;

function checkUF(uf: string) {
  uf.toString().replace(only_alphabeticals_with_space_regex, "").toUpperCase();

  const index = UF_List.findIndex((ufList) => ufList === uf);

  if (index >= 0) {
    return UF_List[index];
  } else {
    throw new Error("UF inválido.");
  }
}

export { UF_List, checkUF };
