import styled from 'styled-components';

export const Container = styled.div``;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
`;

export const TitleWrapper = styled.div`
	margin-bottom: 1rem;
`;

export const cnpjText = styled.span`
	width: max-content;
	white-space: nowrap;
`;

export const StatusBenefits = styled.div`
	display: inline-flex;
	justify-content: center;
	margin-top: 0.4rem;
	position: relative;
	> svg {
		color: var(--primary-red);
		width: 2rem;
		height: 2rem;
	}
	& span {
		visibility: hidden;
		width: max-content;
		max-width: 40rem;
		background-color: var(--light-gray);
		color: var(--white);
		text-align: justify;
		border-radius: 0.6rem;
		padding: 1rem;
		position: absolute;
		z-index: 1;
		top: -1rem;
		right: 60%;
	}
	&:hover span {
		visibility: visible;
	}
`;
