import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { OptionsButton } from '../../../components/Button';

export const Container = styled.div``;

export const cpfText = styled.span`
	width: max-content;
	white-space: nowrap;
`;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
`;

export const Option = css`
	${OptionsButton}
	padding: .5rem 2rem;
	font-size: 1.6rem;
`;

interface OptionLinkButtonProps {
	$disabled?: boolean;
}
export const OptionLinkButton = styled(Link)<OptionLinkButtonProps>`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
	cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;
