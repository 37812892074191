import styled from 'styled-components';

export const Container = styled.div``;

export const OptionsContainer = styled.div`
	display: flex;
	column-gap: 1rem;
`;

export const ReportContainer = styled.div`
	display: flex;
	column-gap: 2rem;
	margin-bottom: 2rem;
`;
