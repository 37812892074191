import { useQuery, useQueryClient } from 'react-query';
import { Company } from '../../../../../@types/Company';
import { getCompanyBalance } from '../../../../../services/queries/Companies';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import * as S from '../styles';
import Loader from '../../../../../components/Loader';
import { convertCentsToReais } from '../../../../../utils/CurrencyConvert';
import * as TableStyles from '../../../../../components/Table/TableStyles';

interface Props {
	company: Company;
}

export function CompanyBalance({ company }: Props) {
	const queryClient = useQueryClient();

	const fetchCompanyBalances = useQuery(
		['companyBalances', company.id],
		() => {
			return getCompanyBalance(company.id);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar os saldos disponíveis da empresa.'
				);
			},
		}
	);

	function refreshPage() {
		queryClient.resetQueries('companyBalances');
	}

	if (fetchCompanyBalances.isLoading || !fetchCompanyBalances.data) {
		return (
			<S.Container>
				<Loader />
			</S.Container>
		);
	}

	return (
		<>
			<TableStyles.ReloadIcon
				type='reload'
				onClick={refreshPage}
				style={{ position: 'absolute', top: 100, right: 100 }}
			/>

			<S.GridContainer>
				<S.InfoContainer>
					<S.InfoTitle style={{ color: 'var(--primary-red)' }}>
						BENEFÍCIOS FLEXÍVEIS
					</S.InfoTitle>
					{convertCentsToReais(fetchCompanyBalances?.data.multiflex_balance)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle style={{ color: 'var(--primary-blue)' }}>
						Bounty Control
					</S.InfoTitle>
					{convertCentsToReais(fetchCompanyBalances?.data.corpway_balance)}
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle style={{ color: 'var(--primary-blue)' }}>
						Saldo dos cartões control
					</S.InfoTitle>
					{convertCentsToReais(
						fetchCompanyBalances?.data.total_corpway_cards_balance
					)}
				</S.InfoContainer>
			</S.GridContainer>
		</>
	);
}
