import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import * as TableStyle from "../Table/TableStyles";

interface PaginationProps {
  onPageChange(page: number): void;
  currentPage: number;
  totalCount: number;
}

export function Pagination({ onPageChange, currentPage, totalCount }: PaginationProps) {
  const pagesLimit = totalCount === 0 ? 1 : Math.ceil(totalCount / 10);

  function onNext() {
    if (currentPage === pagesLimit) return;
    onPageChange(currentPage + 1);
  }

  function onPrevious() {
    if (currentPage === 1) return;
    onPageChange(currentPage - 1);
  }

  return (
    <TableStyle.PaginationContainer data-testid="pagination-container">
      <TableStyle.TableText>
        Página {currentPage} de {pagesLimit}
      </TableStyle.TableText>
      <button>
        <FaAngleLeft onClick={() => onPrevious()} color="#070606" />
      </button>
      <button>
        <FaAngleRight onClick={() => onNext()} color="#000" />
      </button>
    </TableStyle.PaginationContainer>
  );
}
