import api from '../api';

export async function session(email: string, password: string) {
	const response = await api.post('api/v1/cs/session/', { email, password });

	return response.data;
}

export async function refreshToken(refreshToken: string) {
	const { data } = await api.post('/api/v1/cs/refresh-token', {
		refresh_token: refreshToken,
	});

	return data;
}

export async function deleteToken() {
	await api.delete('/api/v1/cs/notification/token');
}
