import React from 'react';
import RESPONSE_CODES from '../pages/Main/CorpwayCards/RESPONSE_CODE.json';
import TXN_TYPES from '../pages/Main/CorpwayCards/TXN_TYPE.json';

function parseTransactionStatus(status: string) {
	return status === 'Approved' ? 'APROVADO' : 'NEGADO';
}

export function getTransactionStatus(response_code: number) {
	const responseCode =	RESPONSE_CODES[response_code.toString() as keyof typeof RESPONSE_CODES];

	if (!responseCode) {
		return { status: '--', msg: '--' };
	}

	const { status, msg } = responseCode;

	return { status, msg };
}

export function parseStatusTransaction(response_code: number) {
	const { status } = getTransactionStatus(response_code);

	switch (status) {
		case 'Approved':
			return 'Aprovada';
		case 'Declined':
			return 'Reprovada';
		default:
			return '--';
	}
}

export function checkDeclineAndStrikeText(
	response_code: number,
	data?: string
): JSX.Element | string {
	if (!data) return '--';

	const { status } = getTransactionStatus(response_code);

	if (status === 'Declined') {
		return React.createElement('s', null, data);
	}

	return data;
}

export function parseExtractTransactionStatus(
	msg_type: number,
	txn_type: number,
	response_code: number,
	cleared: boolean,
	clearing_state: string,
	financial_impact_type: string,
	activeViewer: boolean
) {
	let { status, msg } = getTransactionStatus(response_code);

	if (status === 'Declined') return `Cancelada - ${msg}`;

	if (txn_type === 55 || txn_type === 0) {
		if (msg_type === 1400 || msg_type === 1420) {
			return 'Creditada'; // Estorno externo
		}

		return 'Debitada';
	}

	if (txn_type === 12) return 'Creditada'; // Adição de saldo

	if (txn_type === 13 || txn_type === 14 || txn_type === 68) {
		if (msg_type === 8900 || msg_type === 1220) {
			return 'Debitada';
		}

		return 'Creditada'; // Estorno externo
	}

	if (cleared) return 'Debitada';
	if (!cleared && clearing_state === 'PARTIALLY_CLEARED') {
		return 'Cobrança Parcial';
	}

	if (!cleared) {
		if (activeViewer && financial_impact_type === 'debit') {
			return 'Debitada';
		}

		return 'Processando';
	}

	status = parseTransactionStatus(status);

	const unnecessaryStatusMessages = ['Aprovado', 'Recusado'];

	const status_formatted = unnecessaryStatusMessages.includes(msg)
		? status
		: `${status} - ${msg}`;

	return status_formatted;
}

export function parseTransactionType(
	txn_type: number,
	msg_type: number,
	response_code: number,
	currency: number,
	merchant_country: string
) {
	const { status } = getTransactionStatus(response_code);

	if (status === 'Declined') {
		return 'Compra cancelada';
	}
	switch (txn_type) {
		case 55:
		case 0:
			if (msg_type === 1400 || msg_type === 1420) {
				return 'Estorno externo';
			}

			if (currency === 986 && merchant_country === 'BRA') {
				return 'Compra nacional';
			}

			return 'Compra internacional';
		case 12:
			return msg_type === 8900 ? 'Estorno de IOF' : 'Adição de saldo';
		case 13:
		case 14:
		case 68:
			if (msg_type === 8900) return 'IOF';
			if (msg_type === 1220) return 'Estorno interno';
			return 'Estorno externo';
		default:
			return TXN_TYPES[String(txn_type) as keyof typeof TXN_TYPES];
	}
}

export function getTransactionType(txn_type: string, msg_type: string | null | undefined) : string {
	const txn = Number(txn_type)
	const msg = Number(msg_type)

	if (msg === 8900) {
		if (txn === 12) {
			return 'Estorno de IOF'
		}
		
		if (txn === 13) {
			return 'IOF'
		}
	}

	return TXN_TYPES[txn_type as keyof typeof TXN_TYPES]
}
