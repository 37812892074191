import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';

export const GlobalTheme = createGlobalStyle`
  :root {
    --black: #000;
    --white: #FFF;

    --primary-blue: #27ADFF;
    --primary-purple:#9747FF;
    --primary-red: #FF0037;

    --dark-gray: #506470;
    --mid-gray: #929292;
    --light-gray: #7E7E7E;
    
    --terciary: #2DB9C5;

    font-size: 62.5%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }

  html, body {
    height: 100%;
    overflow-y: hidden;
  }

  html {
    scroll-behavior: smooth;
  }
      
  body {
    background: var(--white);
    color: var(--black);
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
  }

  a, button {
    cursor: pointer;
    text-decoration: 0;
    border: none;
    transition: filter 0.2s;
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
    &:hover {
      filter: brightness(0.9);
    }
    &:disabled {
      opacity: .6;
      cursor: not-allowed;
      &:hover {
        filter: none;
      }
    }
  }

  input {
    -webkit-font-smoothing: antialiased;
    font-family: 'Ubuntu', sans-serif;
  }

  @media(max-width: 700px) {
    :root {
      font-size: 56%;
    }
  }
  
  //Removing steps from input number
  //
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1rem;
    background-color: #e3e8eb;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #AEAEAE;
    border-radius: 1px;
  }
`;
