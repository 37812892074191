import styled from "styled-components";
import { Link } from "react-router-dom";

interface ContainerProps {
  $currentPage: boolean;
}

export const Container = styled(Link)<ContainerProps>`
  display: flex;
  align-items: center;
  margin: 0.8rem 0;
  background-color: ${({ $currentPage }) => ($currentPage ? "rgba(39, 173, 255, 0.15)" : "none")};
  padding: 0.5rem;
  border-radius: 0.8rem;
  svg {
    color: ${({ $currentPage }) => ($currentPage ? "#27ADFF" : "#575757")};
    height: 2rem;
    width: 2rem;
    margin-right: 1.5rem;
  }

  &:hover {
    filter: none;
  }
`;

export const Title = styled.span`
  color: var(--dark-gray);
  font-size: 1.6rem;
`;
