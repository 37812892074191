import { CollaboratorMigration } from '../../@types/CollaboratorMigration';
import {
	FilterParams,
	parseFilterParamsToParams,
} from '../../components/Filter';
import api from '../api';

export interface MigrateCollaboratorRequest {
	collaborator: {
		id: string;
		new_email: string;
	};
	current_company_id: string;
	new_company_id: string;
	reason_for_migration: string;
}

export interface ListMigrationResponse {
	migrations: CollaboratorMigration[];
	totalMigrations: number;
}
export async function migrateCollaborator(
	request: MigrateCollaboratorRequest
): Promise<void> {
	return await api.post('/api/v1/cs/migrates-collaborator', request);
}

export async function getMigrations(page = 1, filterParams: FilterParams[]) {
	const { data } = await api.get<ListMigrationResponse>(
		'api/v1/cs/migrates-collaborator',
		{
			params: {
				page,
				...parseFilterParamsToParams(filterParams),
			},
		}
	);

	return data;
}

export async function getMigration(migration_id: string) {
	const { data } = await api.get<CollaboratorMigration>(
		`api/v1/cs/migrates-collaborator/${migration_id}`
	);

	return data;
}
