import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

export type Order = 'ASC' | 'DESC';
export interface SortColumn {
	name: string;
	order: Order;
}

export function parseSortColumnToParams(sortColumn: SortColumn | null) {
	if (sortColumn)
		return { sort_by: sortColumn.name, sort_order: sortColumn.order };
	return {};
}
export function useSortColumnHook(
	initialValue: SortColumn | null = null,
	onToggleSort: () => void = () => {}
) {
	const [currentSortColumn, setSortColumn] = useState<SortColumn | null>(
		initialValue
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onToggleSort, [currentSortColumn]);

	function toggleSort(columnName: string) {
		if (currentSortColumn?.name === columnName) {
			// column already selected
			if (currentSortColumn.order === 'ASC') {
				// change from asc to desc
				setSortColumn({ name: columnName, order: 'DESC' });
			} else {
				// from desc to unselect column
				setSortColumn(null);
			}
		} else {
			// from null to selecting column
			setSortColumn({ name: columnName, order: 'ASC' });
		}
	}

	function getParsedColumnValue(value: any) {
		if (
			typeof value === 'string' &&
			!isNaN(Number(value)) &&
			value.length > 0
		) {
			return Number(value);
		}

		if (typeof value === 'string' && DateTime.fromISO(value).isValid) {
			return new Date(value);
		}

		return value;
	}

	function checkStringAndNonString(value1: any, value2: any): boolean {
		return (
			(typeof value1 === 'string' && typeof value2 !== 'string') ||
			(typeof value2 === 'string' && typeof value1 !== 'string')
		);
	}

	// sortList can sort numbers, string and dates column, even if numbers and dates are inside "",
	// they are converted appropriately
	function sortList<T>(list: T[]) {
		list = [...list];
		if (currentSortColumn === null) return list;

		const sortedAscList = list.sort((a, b) => {
			let collabAColumnValue = a[currentSortColumn.name as keyof T];
			let collabBColumnValue = b[currentSortColumn.name as keyof T];

			let valueA = getParsedColumnValue(collabAColumnValue);
			let valueB = getParsedColumnValue(collabBColumnValue);

			// name columns with only digits will be converted to numbers, but can't be compared with strings
			// so if one of the values is of different type, convert all to strings
			if (checkStringAndNonString(valueA, valueB)) {
				valueA = String(valueA);
				valueB = String(valueB);
			}

			if (typeof valueA === 'number' && typeof valueB === 'number') {
				return valueA - valueB;
			}

			if (valueA instanceof Date && valueB instanceof Date) {
				return valueA.getTime() - valueB.getTime();
			}

			if (valueA === undefined || valueB === undefined) {
				return String(valueA).localeCompare(String(valueB));
			}

			return valueA.localeCompare(valueB);
		});

		return currentSortColumn.order === 'ASC'
			? sortedAscList
			: sortedAscList.reverse();
	}

	return { currentSortColumn, toggleSort, sortList };
}
