export function checkFinancialImpact(financial_impact: string) {
	switch (financial_impact) {
		case 'credit':
			return 'CRÉDITO';
		case 'debit':
			return 'DÉBITO';
		default:
			return 'NENHUM';
	}
}
