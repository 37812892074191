import PageTitle from '../../../../../../components/PageTitle';
import * as S from './styles';
import { useQuery } from 'react-query';
import { getTransactionV3 } from '../../../../../../services/queries/Transactions';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import Loader from '../../../../../../components/Loader';
import { Transaction } from '../../../../../../@types/Transactions';
import RESPONSE_CODES from '../../constants/RESPONSE_CODE.json';
import { parseTransactionStatus } from '../../../../../../utils/status/parseStatus';
import Modal from '../../../../../../components/Modal';
import { useState } from 'react';
import { MdNotes } from 'react-icons/md';
import {
	convertCentsToCurrency,
	convertCentsToReais,
} from '../../../../../../utils/CurrencyConvert';
import { MCCDetailsModal } from '../MCCDetailsModal';
import cc from 'currency-codes';
import { checkFinancialImpact } from '../../../../../../utils/parseFinancialImpact';
import { getTransactionType } from '../../../../../../utils/transactionUtils';

export const benefits = [
	'ALIMENTAÇÃO',
	'REFEIÇÃO',
	'TRANSPORTE',
	'SAÚDE',
	'HOME OFFICE',
	'CULTURA',
	'VIAGENS',
	'EDUCAÇÃO',
	'ASSISTÊNCIA JURÍDICA',
	'GAMES',
	'PREMIAÇÃO SEM SAQUE',
	'DOAÇÃO E CAUSAS',
	'REEMBOLSO SEM SAQUE',
	'MATERNIDADE E PATERNIDADE',
	'PREMIAÇÃO COM SAQUE',
	'REEMBOLSO COM SAQUE',
];

export enum entry_mode {
	'ICC' = 'Chip',
	'Contactless' = 'Contactless',
	'Contactless Magstripe' = 'Banda magnética contactless',
	'E-Commerce' = 'E-Commerce',
	'Stored Credentials' = 'Credenciais armazenadas',
	'Full Magstripe' = 'Banda magnética',
	'Magstripe Fallback' = 'Banda magnética',
	'Manual' = 'Manual',
	'Unknown' = 'Desconhecido',
}

interface Props {
	transactionId: string;
}

export function TransactionsDetailsModal({ transactionId }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [currentTransactionId, setCurrentTransactionId] =
		useState(transactionId);

	const fetchTransactionQuery = useQuery<Transaction>(
		['transactionDetails', currentTransactionId],
		() => {
			return getTransactionV3(currentTransactionId);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar a transação.'
				);
			},
			enabled: isOpen && !!transactionId,
			refetchOnWindowFocus: false,
		}
	);

	const refetchTransaction = async (newTransactionId: string) => {
		try {
			setCurrentTransactionId(newTransactionId);
			await fetchTransactionQuery.refetch();
		} catch (error) {
			console.error('Error refetching transaction: ', error);
		}
	};

	const handleItemClick = (newTransactionId: string) => {
		refetchTransaction(newTransactionId);
		setIsOpen(true);
	};

	function checkTransactionStatus(cleared: boolean, txn_type: string) {
		if (cleared) {
			return 'Confirmada';
		} else {
			if (txn_type === '12') {
				return '-';
			}
			return 'Não confirmada';
		}
	}

	function checkRefund() {
		let refundsLinked: string[] = [];
		let refundedBy: string[] = [];

		fetchTransactionQuery.data?.linked_transactions.forEach((transaction) => {
			if (
				transaction.link_type === 'REVERSES' ||
				transaction.link_type === 'CLEARING_CREDITS' ||
				transaction.link_type === 'REFUNDS'
			) {
				refundsLinked.push(transaction.internal_transaction_id);
			} else if (
				transaction.link_type === 'REVERSED_BY' ||
				transaction.link_type === 'CLEARING_CREDITED_BY' ||
				transaction.link_type === 'REFUNDED_BY'
			) {
				refundedBy.push(transaction.internal_transaction_id);
			}
		});

		if (refundsLinked.length > 0) {
			return `Estorna a transação = ${refundsLinked.join(', ')}`;
		} else if (refundedBy.length > 0) {
			return `Estornada pela transação = ${refundedBy.join(', ')}`;
		} else if (fetchTransactionQuery.data?.chargeback) {
			const amount = fetchTransactionQuery.data.amount_chargeback;
			return `Valor estornado: ${convertCentsToCurrency(
				fetchTransactionQuery.data.currency,
				Number(amount)
			)}`;
		}

		return 'Transação não é um estorno automático da financeira';
	}

	if (!isOpen) {
		return (
			<S.OpenModalBtn
				onClick={() => setIsOpen(true)}
				data-testid='openButton_test_id'
				data-rh='Detalhamento'
			>
				<MdNotes />
			</S.OpenModalBtn>
		);
	}

	if (!fetchTransactionQuery.data || fetchTransactionQuery.isError) {
		return (
			<>
				<S.OpenModalBtn
					onClick={() => handleItemClick(transactionId)}
					data-testid='openButton_test_id'
					data-rh='Detalhamento'
				>
					<MdNotes />
				</S.OpenModalBtn>

				<Modal
					isOpen={isOpen}
					onRequestClose={() => setIsOpen(false)}
					enableClose
				>
					<S.Container>
						<PageTitle title='Detalhamento de transação' />
						<Loader />
					</S.Container>
				</Modal>
			</>
		);
	}

	return (
		<>
			<S.OpenModalBtn
				onClick={() => setIsOpen(true)}
				data-testid='openButton_test_id'
				data-rh='Detalhamento'
			>
				<MdNotes />
			</S.OpenModalBtn>

			<Modal
				isOpen={isOpen}
				onRequestClose={() => {
					setIsOpen(false);
					setCurrentTransactionId(transactionId);
				}}
				enableClose
			>
				<S.Container>
					<S.TitleWrapper>
						<PageTitle title='Detalhamento de transação' />
					</S.TitleWrapper>

					<S.GridContainer>
						<S.InfoContainer>
							<S.InfoTitle>ID DA TRANSAÇÃO</S.InfoTitle>
							{fetchTransactionQuery.data.internal_transaction_id!}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>TRANSAÇÕES RELACIONADAS</S.InfoTitle>
							{fetchTransactionQuery.data?.linked_transactions.map(
								(transaction) => (
									<div key={transaction.internal_transaction_id}>
										<span
											style={{
												textDecoration: 'underline',
												cursor: 'pointer',
												color: 'blue',
											}}
											onClick={() =>
												handleItemClick(transaction.internal_transaction_id)
											}
										>
											{transaction.internal_transaction_id}
										</span>
									</div>
								)
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Categoria</S.InfoTitle>
							{getTransactionType(fetchTransactionQuery.data.txn_type, fetchTransactionQuery.data.msg_type ?? "0")}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Status</S.InfoTitle>
							{parseTransactionStatus(
								RESPONSE_CODES[
									String(
										fetchTransactionQuery.data.response_code
									) as keyof typeof RESPONSE_CODES
								].status
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Código de status</S.InfoTitle>
							{`${
								RESPONSE_CODES[
									String(
										fetchTransactionQuery.data.response_code
									) as keyof typeof RESPONSE_CODES
								].msg
							} - (${fetchTransactionQuery.data.response_code})`}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Valor</S.InfoTitle>
							{convertCentsToCurrency(
								String(fetchTransactionQuery.data.currency),
								fetchTransactionQuery.data.txn_amount
							)}
						</S.InfoContainer>

						<S.InfoContainer>
							<S.InfoTitle>Status da Transação</S.InfoTitle>
							{checkTransactionStatus(
								fetchTransactionQuery.data.cleared,
								fetchTransactionQuery.data.txn_type
							)}
						</S.InfoContainer>

						{fetchTransactionQuery.data.amount_cleared && (
							<S.InfoContainer>
								<S.InfoTitle>Valor Aprovado</S.InfoTitle>
								{fetchTransactionQuery.data.amount_cleared}
							</S.InfoContainer>
						)}

						{fetchTransactionQuery.data.account_info.account_type !==
						'treasury_account' ? (
							<>
								<S.InfoContainer>
									<S.InfoTitle>Carteira</S.InfoTitle>
									{benefits[
										Number(fetchTransactionQuery.data.balance_account_id) - 1
									] || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>
										Valor da carteira antes da transação
									</S.InfoTitle>
									{convertCentsToReais(
										fetchTransactionQuery.data.available_balance_before!
									)}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Data da tentativa</S.InfoTitle>
									{new Date(fetchTransactionQuery.data.added_time!)
										.toLocaleString()
										.replace(' ', ' às ')}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>ID do Estabelecimento</S.InfoTitle>
									{fetchTransactionQuery.data.merchant_id || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Estabelecimento</S.InfoTitle>
									{fetchTransactionQuery.data.merchant_name || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Cidade do estabelecimento</S.InfoTitle>
									{fetchTransactionQuery.data.merchant_city || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>País do estabelecimento</S.InfoTitle>
									{fetchTransactionQuery.data.merchant_country || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>MOEDA</S.InfoTitle>

									{cc.number(
										String(fetchTransactionQuery.data.currency).padStart(3, '0')
									)?.code ?? 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>MCC</S.InfoTitle>
									{fetchTransactionQuery.data.mcc ? (
										<MCCDetailsModal mccCode={fetchTransactionQuery.data.mcc} />
									) : (
										'N/A'
									)}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Pan do cartão</S.InfoTitle>
									{fetchTransactionQuery.data.pan_masked || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Id do cartão</S.InfoTitle>
									{fetchTransactionQuery.data.card_id || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Forma de pagamento</S.InfoTitle>
									{entry_mode[
										String(
											fetchTransactionQuery.data.pos_entry_mode!
										) as keyof typeof entry_mode
									] || 'N/A'}
								</S.InfoContainer>

								<S.InfoContainer>
									<S.InfoTitle>Impacto financeiro</S.InfoTitle>
									{checkFinancialImpact(
										fetchTransactionQuery.data.financial_impact_type
									)}
								</S.InfoContainer>
							</>
						) : (
							<S.InfoContainer>
								<S.InfoTitle>VISÃO DA TRANSAÇÃO</S.InfoTitle>
								{'Essa transação é visão da empresa'}
							</S.InfoContainer>
						)}

						<S.InfoContainer>
							<S.InfoTitle>Estorno</S.InfoTitle>
							{checkRefund()}
						</S.InfoContainer>
					</S.GridContainer>
				</S.Container>
			</Modal>
		</>
	);
}
