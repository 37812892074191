import { ReactNode, createContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import api from '../services/api';
import { refreshToken, session } from '../services/queries/Session';
import { showErrorMessage } from '../utils/ErrorHandler';

type Props = {
	children: ReactNode;
};

type User = {
	id: string;
	email: string;
};

type AuthState = {
	token: string;
	user: User;
};

export type SignInCredentials = {
	email: string;
	password: string;
};
export type AuthContextData = {
	user: User;
	signIn(credentials: SignInCredentials): Promise<boolean>;
	signOut(): void;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: Props) {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const [data, setData] = useState(() => {
		const token = localStorage.getItem('@Bounty:token');
		const refreshToken = localStorage.getItem('@Bounty:refreshToken');
		const user = localStorage.getItem('@Bounty:user');

		if (token && user && refreshToken) {
			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			setInterceptorResponseOnApi();

			return {
				token,
				user: JSON.parse(user),
			};
		}

		return {} as AuthState;
	});

	const signInQuery = useMutation(({ email, password }: SignInCredentials) => {
		return session(email, password);
	});
	const refreshTokenQuery = useMutation((refreshTokenParam: string) =>
		refreshToken(refreshTokenParam)
	);

	async function signIn({ email, password }: SignInCredentials) {
		try {
			const response = await signInQuery.mutateAsync({ email, password });
			const { token, user, refreshToken } = response;

			const userData: User = {
				id: user.id,
				email: user.email,
			};

			localStorage.setItem('@Bounty:token', token);
			localStorage.setItem('@Bounty:refreshToken', refreshToken);
			localStorage.setItem('@Bounty:user', JSON.stringify(userData));

			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			setInterceptorResponseOnApi();

			setData({ token, user });

			return true;
		} catch (err) {
			showErrorMessage(err as Error, 'Ocorreu um erro ao fazer login.');
			return false;
		}
	}

	async function clearAuthState() {
		setData({} as AuthState);

		localStorage.removeItem('@Bounty:token');
		localStorage.removeItem('@Bounty:refreshToken');
		localStorage.removeItem('@Bounty:user');
	}

	async function signOut() {
		queryClient.cancelQueries();
		clearAuthState();
		navigate('/session');
	}

	// This function sets a response interceptor at the api, so on each request if the token expires, refresh it)
	function setInterceptorResponseOnApi() {
		api.interceptors.response.use(
			(response) => {
				return response;
			},

			async (err: any) => {
				const originalConfig = err.config;
				let refreshToken = localStorage.getItem('@Bounty:refreshToken');

				// Access Token was expired
				if (
					refreshToken &&
					err.response.status === 401 &&
					!originalConfig._retry
				) {
					originalConfig._retry = true;
					try {
						const data = await refreshTokenQuery.mutateAsync(refreshToken);
						const newToken = data.token;
						const newRefreshToken = data.refreshToken;

						api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
						localStorage.setItem('@Bounty:token', newToken);

						if (newRefreshToken)
							// refreshToken was expired as well, so update it with the new one
							localStorage.setItem('@Bounty:refreshToken', newRefreshToken);
						return api({
							...originalConfig,
							headers: { Authorization: `Bearer ${newToken}` },
						});
					} catch (_error) {
						console.log('SAINDO, deu ERRO ATUALIZANDO O TOKEN, catch', err);
						signOut();
						return Promise.reject(_error);
					}
				}
				return Promise.reject(err);
			}
		);
	}

	return (
		<AuthContext.Provider
			value={{
				user: data.user,
				signIn,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
