import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";

const Text = styled.span`
  color: var(--black);
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  column-gap: 1rem;
  row-gap: 1rem;
  & svg {
    color: var(--primary-purple);
  }
`;
interface EmptyContentProp {
  text?: string;
  big?: boolean;
}

export function EmptyContent({ text = "Sem dados para exibir", big = false }: EmptyContentProp) {
  return (
    <Text style={big ? { flexDirection: "column" } : {}}>
      <FaInfoCircle size={big ? "6rem" : "2rem"} />
      {text}
    </Text>
  );
}
