import styled from 'styled-components';

export const Container = styled.div`
	width: 50vw;
	height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
`;

export const DataContainer = styled.div`
	display: flex;
	margin-top: 3rem;
	column-gap: 5rem;
	justify-content: space-between;
	row-gap: 2rem;
	flex-wrap: wrap;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InfoLabel = styled.span`
	font-size: 1.8rem;
`;

export const InfoContent = styled.span`
	font-size: 1.6rem;
	color: var(--mid-gray);
`;

export const openModalBttn = styled.button`
	background-color: var(--primary-blue);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	padding: 0.8rem;
	margin: 0 auto;
	& svg {
		width: 100%;
		height: 100%;
		color: var(--white);
	}
`;
