import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import PageTitle from '../../../../components/PageTitle';
import { useSortColumnHook } from '../../../../hooks/useSortColumnHook';
import { useTransactionsListStore } from '../../../../stores/useTransactionsListStore';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import * as S from './styles';
import * as TableStyles from '../../../../components/Table/TableStyles';
import { Filter } from '../../../../components/Filter';
import { Pagination } from '../../../../components/Pagination';
import { EmptyContent } from '../../../../components/EmptyContent';
import { SortColumnButton } from '../../../../components/SortColumnButton';
import RESPONSE_CODES from '../Transactions/constants/RESPONSE_CODE.json';
import { MCCDetailsModal } from '../Transactions/components/MCCDetailsModal';
import { parseTransactionStatus } from '../../../../utils/status/parseStatus';
import { convertCentsToCurrency } from '../../../../utils/CurrencyConvert';
import { TransactionsDetailsModal } from '../Transactions/components/TransactionsDetailsModal';
import { getCollaboratorTransactionsV3 } from '../../../../services/queries/Collaborators';
import { PdfReportGenerator } from './PdfReportGenerator';
import { SpreadsheetReportGenerator } from './SpreadsheetReportGenerator';
import { getTransactionType } from '../../../../utils/transactionUtils';

export function TransactionsV2() {
	const { id } = useParams<{ id: string }>();
	const queryClient = useQueryClient();
	const [currentPage, setCurrentPage] = useState(1);
	const { currentSortColumn, toggleSort } = useSortColumnHook();

	const [filterParams, setFiltersParams, resetFilters] =
		useTransactionsListStore((state) => [
			state.filterParams,
			state.setFiltersParams,
			state.resetFilters,
		]);

	// Reseting filters when navigating in and out. Since Transactions page shares the same transactionsListStore,
	// we need to make sure that the store is reseted in this Collaborators Transactions page
	useEffect(() => {
		resetFilters();
		return resetFilters;
	}, [resetFilters]);

	const fetchTransactionsQuery = useQuery(
		['transactionsV2List', id, currentPage, filterParams, currentSortColumn],
		() => {
			return getCollaboratorTransactionsV3(
				id!,
				currentPage,
				filterParams,
				currentSortColumn
			);
		},
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar as transações do colaborador.'
				);
			},
			enabled: !!id,
			refetchOnWindowFocus: false,
		}
	);

	function refreshPage() {
		setCurrentPage(1);
		queryClient.resetQueries('transactionsV2List');
	}

	if (fetchTransactionsQuery.isLoading) {
		return (
			<S.Container>
				<PageTitle title='Transações do colaborador' />
				<Loader />
			</S.Container>
		);
	}

	if (fetchTransactionsQuery.isError) {
		return (
			<S.Container>
				<TableStyles.TableHeaderContainer>
					<PageTitle title={`Transações do colaborador`} />

					<Filter
						filterParams={filterParams}
						onFiltersChanged={(updatedFilters) => {
							setFiltersParams(updatedFilters);
							setCurrentPage(1);
						}}
					/>

					{/* Pagination spacing placeholder */}
					<div style={{ marginLeft: 'auto' }}></div>
					<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
				</TableStyles.TableHeaderContainer>
				<EmptyContent big />
			</S.Container>
		);
	}

	return (
		<S.Container>
			<TableStyles.TableHeaderContainer>
				<PageTitle
					title={`Transações do colaborador - ${
						fetchTransactionsQuery.data!.collaborator.collaborator_name
					}`}
					totalRecords={fetchTransactionsQuery.data?.totalTransactions}
				/>

				<Filter
					filterParams={filterParams}
					onFiltersChanged={(updatedFilters) => {
						setFiltersParams(updatedFilters);
						setCurrentPage(1);
					}}
				/>

				<Pagination
					onPageChange={(page) => setCurrentPage(page)}
					currentPage={currentPage}
					totalCount={fetchTransactionsQuery.data?.totalTransactions!}
				/>
				<TableStyles.ReloadIcon type='reload' onClick={refreshPage} />
			</TableStyles.TableHeaderContainer>

			{fetchTransactionsQuery.data?.totalTransactions! > 0 ? (
				<>
					<TableStyles.Table>
						<TableStyles.TableHeader>
							<TableStyles.TableRow>
								<TableStyles.TableHeaderCell>ID</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									CATEGORIA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>MCC</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									STATUS
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'merchant_name'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('merchant_name')}
									/>
									ESTABELECIMENTO
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'added_time'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('added_time')}
									/>
									DATA DA TENTATIVA
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell>
									<SortColumnButton
										order={
											currentSortColumn?.name === 'txn_amount'
												? currentSortColumn.order
												: null
										}
										onToggleSort={() => toggleSort('txn_amount')}
									/>
									VALOR
								</TableStyles.TableHeaderCell>
								<TableStyles.TableHeaderCell style={{ textAlign: 'center' }}>
									OPÇÕES
								</TableStyles.TableHeaderCell>
							</TableStyles.TableRow>
						</TableStyles.TableHeader>

						<TableStyles.TableBody>
							{fetchTransactionsQuery.data?.transactions.map((transaction) => (
								<TableStyles.TableRow key={transaction.internal_transaction_id}>
									<TableStyles.TableData>
										{transaction.internal_transaction_id}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{getTransactionType(transaction.txn_type, transaction.msg_type ?? '0')}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{transaction.mcc ? (
											<MCCDetailsModal mccCode={transaction.mcc} />
										) : (
											'-'
										)}
									</TableStyles.TableData>
									<TableStyles.TableData
										style={{
											color:
												RESPONSE_CODES[
													String(
														transaction.response_code
													) as keyof typeof RESPONSE_CODES
												].status === 'Approved'
													? 'green'
													: 'red',
										}}
									>
										{parseTransactionStatus(
											RESPONSE_CODES[
												String(
													transaction.response_code
												) as keyof typeof RESPONSE_CODES
											].status
										)}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{transaction.merchant_name || '-'}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{new Date(transaction.added_time).toLocaleString()}
									</TableStyles.TableData>
									<TableStyles.TableData>
										{convertCentsToCurrency(
											String(transaction.currency),
											transaction.txn_amount
										)}
									</TableStyles.TableData>
									<TableStyles.TableData>
										<S.OptionsContainer>
											<TransactionsDetailsModal
												transactionId={transaction.internal_transaction_id}
											/>
										</S.OptionsContainer>
									</TableStyles.TableData>
								</TableStyles.TableRow>
							))}
						</TableStyles.TableBody>
					</TableStyles.Table>

					{filterParams.find((f) => f.value) && (
						<S.ReportContainer>
							<PdfReportGenerator
								collaborator={fetchTransactionsQuery.data?.collaborator!}
								filterParams={filterParams}
								sortColumn={currentSortColumn}
							/>

							<SpreadsheetReportGenerator
								collaborator={fetchTransactionsQuery.data?.collaborator!}
								filterParams={filterParams}
								sortColumn={currentSortColumn}
							/>
						</S.ReportContainer>
					)}
				</>
			) : (
				<EmptyContent text={'Sem dados disponíveis para este mês'} big />
			)}
		</S.Container>
	);
}
